import React, { Component } from "react";
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  CardBody, Table,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import components
import PageBreadcrumb from "../../../components/Shared/PageBreadcrumb";

//Import Images
import product1 from "../../../assets/images/shop/product/s1.jpg";
import product2 from "../../../assets/images/shop/product/s2.jpg";
import product3 from "../../../assets/images/shop/product/s3.jpg";
import product4 from "../../../assets/images/shop/product/s4.jpg";
import product5 from "../../../assets/images/shop/product/s5.jpg";
import product6 from "../../../assets/images/shop/product/s6.jpg";
import product7 from "../../../assets/images/shop/product/s7.jpg";
import product8 from "../../../assets/images/shop/product/s8.jpg";
import product9 from "../../../assets/images/shop/product/s9.jpg";
import product10 from "../../../assets/images/shop/product/s10.jpg";
import product11 from "../../../assets/images/shop/product/s11.jpg";
import product12 from "../../../assets/images/shop/product/s12.jpg";
import product13 from "../../../assets/images/shop/product/s13.jpg";
import product14 from "../../../assets/images/shop/product/s14.jpg";
import product15 from "../../../assets/images/shop/product/s15.jpg";

import prodtctOverlay1 from "../../../assets/images/shop/product/s-1.jpg";
import prodtctOverlay2 from "../../../assets/images/shop/product/s-2.jpg";
import prodtctOverlay3 from "../../../assets/images/shop/product/s-3.jpg";
import prodtctOverlay4 from "../../../assets/images/shop/product/s-4.jpg";
import prodtctOverlay5 from "../../../assets/images/shop/product/s-5.jpg";
import prodtctOverlay6 from "../../../assets/images/shop/product/s-6.jpg";
import prodtctOverlay7 from "../../../assets/images/shop/product/s-7.jpg";
import prodtctOverlay8 from "../../../assets/images/shop/product/s-8.jpg";
import prodtctOverlay9 from "../../../assets/images/shop/product/s-9.jpg";
import prodtctOverlay10 from "../../../assets/images/shop/product/s-10.jpg";
import prodtctOverlay11 from "../../../assets/images/shop/product/s-11.jpg";
import prodtctOverlay12 from "../../../assets/images/shop/product/s-12.jpg";
import prodtctOverlay13 from "../../../assets/images/shop/product/s-13.jpg";
import prodtctOverlay14 from "../../../assets/images/shop/product/s-14.jpg";
import prodtctOverlay15 from "../../../assets/images/shop/product/s-15.jpg";
import CurrencyRatesService from "../../../api/CurrencyRatesService";
import AuthorityService from "../../../api/AuthorityService";
import {API_URL} from "../../../Constants";
import DocFilesService from "../../../api/DocFilesService";
import GalleryPictureJobSearchService from "../../../api/GalleryPictureJobSearchService";
import GalleryVideoSearchService from "../../../api/GalleryVideoSearchService";
import AuthoritySearchService from "../../../api/AuthoritySearchService";
import NewsSearchService from "../../../api/NewsSearchService";
import history from "../../../history";
import DocFilesSearchService from "../../../api/DocFilesSearchService";
import Button from "@material-ui/core/Button";
class SearchResults extends Component {

  constructor(props) {
    const {t}=props.translation
    super(props);
    this.state = {
      pathItems: [

        { id: 1, name: t('unitarK'), link: "/" },
        { id: 2, name: t('qidiruvN'), link: "" },

      ],

      rate01:'',
      rate02:'',
      rate03:'',
      rate04:'',
      authorities:false,
      news:false,
      docfiles:false,
      gallerypicture:false,
      galleryvideo:false,
      countAuthorities:0,
      countNews:0,
      countDocFiles:0,
      countGalleryPictures:0,
      countGalleryVideos:0

    };
  }

  componentDidMount() {
    this._speech = new SpeechSynthesisUtterance();
    window.addEventListener("scroll", this.scrollNavigation, true);
    window.scrollTo({top:0,behavior:"smooth"})

    CurrencyRatesService.getCUrrency(history.location.state).then((response) => {
      this.setState({
        currencyRates: response.data,
        rate01: response.data[0],
        rate02: response.data[1],
        rate03: response.data[2],
        rate04: response.data[3]
      })
    })

    DocFilesSearchService.getTotalCount(history.location.state).then((res)=>
    {
      this.setState({
        countDocFiles:res.data
      })
      if(res.data!==0)
        this.setState({
          docfiles:true
        })

    })
    GalleryPictureJobSearchService.getTotalCount(history.location.state).then((res)=>
    {
      this.setState({
        countGalleryPictures:res.data
      })
      if(res.data!==0)
        this.setState({
          gallerypicture:true
        })

    })

    GalleryVideoSearchService.getTotalCount(history.location.state).then((res)=>
    {
      this.setState({
        countGalleryVideos:res.data
      })
      if(res.data!==0)
        this.setState({
          galleryvideo:true
        })

    })

    AuthoritySearchService.getTotalCount(history.location.state).then((res)=>
    {
      this.setState({
        countAuthorities:res.data
      })
      if(res.data!==0)
        this.setState({
          authorities:true
        })

    })
    NewsSearchService.getTotalCount(history.location.state).then((res)=>
    {
      this.setState({
        countNews:res.data
      })
      if(res.data!==0)
        this.setState({
          news:true
        })

    })

  }
  speak=(event)=>{
    if(window.isSpeakingEnabled)
    {
      this._speech.text =event.currentTarget.textContent;
      this._speech.lang = 'ru-RU'
      window.speechSynthesis.speak(this._speech);}
  }
  gotoDocfiles=()=>{

    history.push({
      pathname: '/file-search',
      state: history.location.state
    })

  }

  gotoGallerypictures=()=>{

    history.push({
      pathname: '/images-search',
      state: history.location.state
    })

  }

  gotoGalleryVideos=()=>{
    history.push({
      pathname: '/video-search',
      state: history.location.state
    })
  }

  gotoNews=()=>{
    history.push({
      pathname: '/news-search',
      state: history.location.state
    })
  }

  gotoAuthorities=()=>{
    history.push({
      pathname: '/authority-search',
      state: history.location.state
    })
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);

  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      if(document.getElementById("topnav")!==null)
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      if(document.getElementById("topnav")!==null)
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  render() {
    const { data, isLoading, errorMessage }=this.props.weatherr
    const {t}=this.props.translation

    return (
      <React.Fragment>
        {/* breadcrumb */}

        <PageBreadcrumb title={t('qidiruvN')} pathItems={this.state.pathItems} />
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row>
              <Col lg={3} md={5} xs={12}>
                <div onClick={this.speak}>
                <ReactWeather
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    data={data}
                    lang="ru"
                    locationLabel="Navoiy"
                    unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                    showForecast
                />
                </div>
                <Card className="border-0 sidebar sticky-bar">

                  <CardBody className="p-0">

                    <div className="widget">

                      <div id="search2" className="widget-search mb-0">


                      </div>
                    </div>

                    <div className="widget mt-4 pt-2">
                      <h4 onClick={this.speak}className="widget-title">{t('valyutaKurslari')} {this.state.rate01.Date} {t('holatiga')}</h4>
                      <ul className="list-unstyled mt-4 mb-0 blog-categories">
                        <li onClick={this.speak}>
                          {this.state.rate01.Nominal} {this.state.rate01.CcyNm_RU} = {this.state.rate01.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate02.Nominal} {this.state.rate02.CcyNm_RU} = {this.state.rate02.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate03.Nominal} {this.state.rate03.CcyNm_RU} = {this.state.rate03.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate03.Nominal} {this.state.rate04.CcyNm_RU} = {this.state.rate04.Rate} UZS
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={9} md={8} xs={12} className="mt-5 pt-2 mt-sm-0 pt-sm-0">

                <div className="border-bottom pb-4">
                  <h5 onClick={this.speak}>{t("filelarBolimi")}</h5>
                  <p onClick={this.speak} className="text-muted mb-0" style={{fontSize:"20px"}}>
                    {t("fileSearchResults1")}
                    <strong onClick={this.speak}style={{fontSize:"25px",color:"black"}}>{this.state.countDocFiles}</strong>
                    {t("searchResults2")}
                  </p>
                  <br/>
                  {this.state.docfiles&&<Button onClick={this.gotoDocfiles} className="btn btn-primary">
                    {t("natijalarniK")} <i className="mdi mdi-chevron-right"></i>
                  </Button>}
                </div>

                <div className="border-bottom pb-4">
                  <h5 onClick={this.speak}>{t("news")}</h5>
                  <p onClick={this.speak}className="text-muted mb-0" style={{fontSize:"20px"}}>
                    {t("newsSearchResults1")}
                    <strong onClick={this.speak}style={{fontSize:"25px",color:"black"}}>{this.state.countNews}</strong>
                    {t("searchResults2")}
                  </p>
                  <br/>
                  {this.state.news&&<Button onClick={this.gotoNews} className="btn btn-primary">
                    {t("natijalarniK")} <i className="mdi mdi-chevron-right"></i>
                  </Button>}
                </div>

                <div className="border-bottom pb-4">
                  <h5 onClick={this.speak}> {t("gallery")}</h5>
                  <p onClick={this.speak}className="text-muted mb-0" style={{fontSize:"20px"}}>
                    {t("galleryPictureJobSearchResults1")}
                    <strong onClick={this.speak}style={{fontSize:"25px",color:"black"}}>{this.state.countGalleryPictures}</strong>
                    {t("searchResults2")}
                  </p>
                  <br/>
                  {this.state.gallerypicture&&<Button onClick={this.gotoGallerypictures} className="btn btn-primary">
                    {t("natijalarniK")} <i className="mdi mdi-chevron-right"></i>
                  </Button>}
                </div>

                <div className="border-bottom pb-4">
                  <h5 onClick={this.speak}>{t("videoGalereya")}</h5>
                  <p onClick={this.speak}className="text-muted mb-0" style={{fontSize:"20px"}}>
                    {t("galleryVideoSearchResult1")}
                    <strong onClick={this.speak}style={{fontSize:"25px",color:"black"}}>{this.state.countGalleryVideos}</strong>
                    {t("searchResults2")}
                  </p>
                  <br/>
                  {this.state.galleryvideo&&<Button onClick={this.gotoGalleryVideos} className="btn btn-primary">
                    {t("natijalarniK")} <i className="mdi mdi-chevron-right"></i>
                  </Button>}
                </div>

                <div className="border-bottom pb-4">
                  <h5 onClick={this.speak}>{t("authority")}</h5>
                  <p className="text-muted mb-0" style={{fontSize:"20px"}}>
                    {t("authoritySearchResults1")}
                    <strong onClick={this.speak}style={{fontSize:"25px",color:"black"}}>{this.state.countAuthorities}</strong>
                    {t("searchResults2")}
                  </p>
                  <br/>
                  {this.state.authorities&&<Button onClick={this.gotoAuthorities} className="btn btn-primary">
                    {t("natijalarniK")} <i className="mdi mdi-chevron-right"></i>
                  </Button>}
                </div>
                {/*<p className="text-muted mb-0" style={{fontSize:"20px"}}>*/}
                {/*  {t("ifSearchNoresults")}*/}

                {/*</p>*/}





              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ()=>{

  const {t} = useTranslation();
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '6057277f00c3fed52889f1abe246f18d',
    lat: 40.105319569073735,
    lon: 65.37402492454045,
    lang: 'ru',
    unit: 'metric', // values are (metric, standard, imperial)
  });
  return (
      <SearchResults translation={{t}} weatherr={{ data, isLoading, errorMessage }} />
  )
};
