import React, { Component } from "react";
import {
  Container,
  Row,
  Col,

  PaginationItem,
  PaginationLink,
  Card,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css'
//Import components
import PageBreadcrumb from "../../../components/Shared/PageBreadcrumb";
import PageSearchSidebar from "../../../components/Shared/PageSearchSidebar";
import history from "../../../history";
// import images
import blog13 from "../../../assets/images/work/13.jpg";
import blog14 from "../../../assets/images/news.jpg";
import blog15 from "../../../assets/images/work/15.jpg";
import blog16 from "../../../assets/images/work/16.jpg";
import blog17 from "../../../assets/images/work/17.jpg";
import blog18 from "../../../assets/images/work/18.jpg";
import blog19 from "../../../assets/images/work/19.jpg";

import blog01 from "../../../assets/images/blog/01.jpg";
import blog07 from "../../../assets/images/blog/07.jpg";
import blog08 from "../../../assets/images/blog/08.jpg";
import {withTranslation} from "react-i18next";
import NewsService from "../../../api/NewsService";
import {API_URL} from "../../../Constants";
import NewsSearchService from "../../../api/NewsSearchService";

class NewsSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathItems: [
        //id must required
        { id: 1, name: this.props.t('unitarK'), link: "/" },
        { id: 2, name: this.props.t('qidiruvN'), link: "" },

      ],
     news:[],
      current:1,
      size:5,
      total:0,
      title: history.location.state+ ' ' +this.props.t('kalitSBQN')
    };
  }

  componentDidMount() {
    NewsSearchService.search(history.location.state,this.state.current-1,this.state.size).then((res)=>{

      this.setState({
        news:res.data,
        total:res.headers['total']
      })

    })

    window.scrollTo({top:0,behavior:"smooth"})
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  onChange=(x)=>{
    NewsSearchService.search(history.location.state,x-1,this.state.size).then((res)=>{
      this.setState({
        news:res.data,
        current:x,
        total:res.headers['total']
      })
    })


  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        <PageBreadcrumb
          title={this.state.title}
          pathItems={this.state.pathItems}
        />

        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row>
              <Col lg={8} xs={12}>
                <Row>
                  {this.state.news.map((blog, key) =>
                   (
                      <Col key={key} xs={12} className="mb-4 pb-2">
                        <Card className="blog rounded border-0 shadow overflow-hidden">
                          <Row className="align-items-center no-gutters">
                            <Col md={6}>
                              <img
                                  src={`${API_URL}/api/images/` + blog.mainimage}
                                  className="img-fluid"
                                  alt="Landrick"
                              />
                              <div className="overlay bg-dark"></div>
                              <div className="author">
                                <small className="text-light date">
                                  <i className="mdi mdi-calendar-check"></i>{" "}
                                  {(new Date(blog.createdAt).toLocaleDateString())}
                                </small>
                              </div>
                            </Col>
                            <Col md={6}>
                              <CardBody className="content">
                                <h5>
                                  <Link
                                    to="#"
                                    className="card-title title text-dark"
                                  >
                                    {blog.title}
                                  </Link>
                                </h5>

                                <div className="post-meta d-flex justify-content-between mt-3">

                                  <Link
                                      to={{
                                        pathname: `/page-blog-detail`,
                                        state:{blog}
                                      }}

                                    className="text-muted readmore"
                                  >
                                    {this.props.t("koproqB")}{" "}
                                    <i className="mdi mdi-chevron-right"></i>
                                  </Link>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    )
                  )}

                  <Col xs={12}>
                    <Pagination
                        onChange={(x)=>this.onChange(x)}
                        current={this.state.current}
                        total={this.state.total}
                        pageSize={5}
                    />
                  </Col>
                </Row>
              </Col>

              {/* sidebar */}
              <Col lg={4} xs={12} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <PageSearchSidebar
                  blog01={blog01}
                  blog07={blog07}
                  blog08={blog08}
                />
              </Col>
              {/* <!-- END SIDEBAR --> */}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(NewsSearchResults);
