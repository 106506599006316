import React, {Component} from "react";
import {Card, CardBody, Col} from "reactstrap";
import CountUp from "react-countup";
import i18next from "i18next";
import ReactDOM from 'react-dom';

class Counter2 extends Component {


    componentDidMount() {
        this._speech = new SpeechSynthesisUtterance();
    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru-RU'
            window.speechSynthesis.speak(this._speech);}
    }
    click = () => {
        window.scrollTo({top: 824, behavior: "smooth"});
    }

    render() {
        return (
            <React.Fragment>
                {this.props.counters.map((counter, key) => (
                    <div style={{maxwidth: "211px"}} key={key}>
                        <button onClick={this.click} className="btn btn-light  small"
                                style={{margin: "2px", height: "272px", width: '217.65px'}}>
                            <Card className="explore-feature border-1 rounded text-center bg-white">
                                <CardBody>
                                    <div className="counter-box text-center">
                                        <img src={counter.image} height="50" alt=""/>
                                        <h2 className="mb-0 mt-4">
                <span className="counter-value" style={{fontSize: "20px"}}>
                  {" "}
                    <CountUp
                        start={counter.start}
                        end={counter.value}
                        duration={8}
                    />
                </span>
                                            <span className="counter-value" onClick={this.speak}
                                                  style={{fontSize: "18px"}}>{counter.postfix}</span>

                                        </h2>
                                         <div className="counter-head text-dark" onClick={this.speak}
                                                                              style={{
                                                                                  fontSize: "15px",
                                                                                  fontWeight: "bold"
                                                                              }}>{counter.title}</div>


                                    </div>
                                </CardBody>
                            </Card>
                        </button>
                    </div>
                ))}
            </React.Fragment>
        );
    }
}

export default Counter2;
