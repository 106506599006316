import {API_URL} from "../Constants";
import instance from "./api";
import i18next from "i18next";

class NewsService {
    getAllNews(page,size)
    {
        return instance.get(`${API_URL}/api/news/?page=${page}&size=${size}`,{headers:{
                "Locale":i18next.language
            }})
    }

}
export default new NewsService()
