import {API_URL} from "../Constants";
import instance from "./api";
import i18next from "i18next";

class GalleryJobPictureService {

    getAllFatherPictures(page,size)
    {
        return instance.get(`${API_URL}/api/jobimage/?page=${page}&size=${size}`,{headers:{
            'Locale':i18next.language
            }})
    }

    getExperience()
    {
        {
            return instance.get(`${API_URL}/api/aboutus/experience/`)
        }
    }
    getFamilyPictures(familyid)
    {
        return instance.get(`${API_URL}/api/jobimage/${familyid}`,{headers:{
                'Locale':i18next.language
            }})
    }


}
export default new GalleryJobPictureService()
