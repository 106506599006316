import {API_URL} from "../Constants";
import i18next from "i18next";
import instance from "./api";

class MessageService {

    leaveMessage(message)
    {
        return instance.post(`${API_URL}/api/message/`,message,{
            withCredentials:true
        })
    }
    getall()
    {
        return instance.get(`${API_URL}/api/message/all/`,{
            withCredentials:true
        })
    }
    getanswered()
    {
        return instance.get(`${API_URL}/api/message/answered/`,{
            withCredentials:true
        })
    }

    updateMes(m)
    {
        return instance.post(`${API_URL}/api/message/update/`,m,{
            withCredentials:true
        })
    }

    deleteMes(m)
    {
        return instance.post(`${API_URL}/api/message/delete/`,m,{
            withCredentials:true
        })
    }

}
export default new MessageService()
