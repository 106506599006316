import instance from "./api";
import {API_URL} from "../Constants";


class AvtoYullariService {

    getAll()
    {
        return instance.get(`${API_URL}/api/avtoyullari/`)
    }}
export default new AvtoYullariService()
