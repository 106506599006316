import React, { Component } from "react";
import { Link } from "react-router-dom";
import {API_URL} from "../../../Constants";
import FileDownload from 'js-file-download'
import axios from "axios";
import download from 'download'
import { Document, Page } from 'react-pdf';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Table,
} from "reactstrap";

import history from "../../../history";
import {withTranslation} from "react-i18next";
import FeatherIcon from "feather-icons-react";
import CommonSidebar2 from "./CommonSidebar2";
import DocFilesService from "../../../api/DocFilesService";
import { promisify } from 'util';
import AuthenticationService from "../../../api/AuthenticationService";

 class Vacancy extends Component {

   constructor() {
     super();
     this.state={
       files:[]
     }
   }
   speak=(event)=>{
     if(window.isSpeakingEnabled)
     {
       this._speech.text =event.currentTarget.textContent;
       this._speech.lang = 'ru-RU'
       window.speechSynthesis.speak(this._speech);}
   }
  componentDidMount() {
    this._speech = new SpeechSynthesisUtterance();
    window.scrollTo({top:0,behavior:"smooth"})
    window.addEventListener("scroll", this.scrollNavigation, true);

    DocFilesService.getDocFilesList('TYPE_VOKANS').then((res)=>
    {
      this.setState({
        files:res.data
      })

    })

  }

   viewPDF=(file)=>{

     AuthenticationService.check22().then((res)=>
     {
       window.open(`${API_URL}/api/filesdf/open/?year=`+file.id.year+'&chorak='+file.id.chorak+'&locale='+file.id.locale+'&typ='+file.id.typ,file.name+'.pdf');

     })


   }

   download=(file)=>{

     AuthenticationService.check22().then((res)=>{
       window.open(`${API_URL}/api/filesdf/download/?year=`+file.id.year+'&chorak='+file.id.chorak+'&locale='+file.id.locale+'&typ='+file.id.typ,file.name+'.pdf');

     })

   }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

   scrollNavigation = () => {
     var doc = document.documentElement;
     var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
     if(document.getElementById("topnav")!==null) {
       if (top > 80) {
         document.getElementById("topnav").classList.add("nav-sticky");
       } else {
         document.getElementById("topnav").classList.remove("nav-sticky");
       }
     }}
  render() {
    return (
      <React.Fragment>
        <section className="bg-half bg-light d-table w-100">
          <Container>
            <Row className="justify-content-center">
              <Col lg={12} className="text-center">
                <div className="page-next-level">
                  <h4 onClick={this.speak} className="title"> {this.props.t('bushIU')} </h4>
                  <div className="page-next">
                    <nav aria-label="breadcrumb" className="d-inline-block">
                      <ul className="breadcrumb bg-white rounded shadow mb-0">
                        <li onClick={this.speak}className="breadcrumb-item">
                          <Link onClick={this.speak}to="/">{this.props.t('unitarK')}</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {this.props.t('bushIU')}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <div className="container" style={{minWidth:"1400px"}}>
            <Row>
              <Col lg={9}>
                <div className="table-responsive bg-white shadow rounded mt-4">
                  <Table className="mb-0 table-center">
                    <thead className="bg-light">
                      <tr>
                        <th onClick={this.speak}scope="col" style={{ minWidth: "300px" }}>
                          {this.props.t('dokumentN')}
                        </th>
                        <th
                          scope="col"
                          className="text-center"
                          style={{ maxWidth: "150px" }}
                          onClick={this.speak}
                        >
                          {this.props.t('hajmi')}
                        </th>
                        <th
                          scope="col"
                          className="text-center"
                          style={{ width: "100px" }}
                        >

                        </th>
                        <th
                          scope="col"
                          className="text-center"
                          style={{ width: "100px" }}
                        >

                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.files.map((f,k)=><tr key={k}>
                      <td>
                        <div className="media">
                          <FeatherIcon icon="file" className="fea icon-ex-md"/>
                          <div className="content ml-3" style={{fontSize: "14px"}}>
                            <Link
                                to="#"
                                className="forum-title text-primary font-weight-bold"
                                onClick={this.speak}
                            >
                              {f.name}
                            </Link>
                            <p className="text-muted small mb-0 mt-2">

                            </p>
                          </div>
                        </div>
                      </td>
                      <td onClick={this.speak}className="text-center small h6">{f.docFileLocation.size}</td>
                      <td onClick={this.speak}className="text-center small">
                        <button className="btn small btn-primary" onClick={()=>this.viewPDF(f)}
                                style={{fontSize: "10px"}}> {this.props.t('korish')}</button>
                      </td>
                      <td onClick={this.speak}className="text-center small">
                        <button className="btn small btn-primary"
                                style={{fontSize: "10px"}} onClick={()=>this.download(f)}>{this.props.t('yuklash')}</button>
                      </td>
                    </tr>)}
                    </tbody>
                  </Table>
                </div>
              </Col>

              <CommonSidebar2 />
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Vacancy)