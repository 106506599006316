import {API_URL} from "../Constants";
import instance from "./api";
import i18next from "i18next";

class GalleryPictureFactoryService {

    get(eZavod)
    {
        return instance.get(`${API_URL}/api/factoryimage/${eZavod}`,{headers:{
            'Locale':i18next.language
            }})
    }




}
export default new GalleryPictureFactoryService()
