import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  Input
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ReCaptchaV2 from 'react-google-recaptcha'
//Import Icons
import FeatherIcon from "feather-icons-react";
import AuthorityService from "../../../api/AuthorityService";
import AuthenticationService from "../../../api/AuthenticationService";
import history from "../../../history";
import {REACT_APP_SITE_KEY} from "../../../Constants";
const Loader = () => (
    <div class="divLoader" style={{display: "flex",justifyContent: "center",alignItems:"center",position:'fixed',
      padding:0,
      margin:0,
      background:"white",
      top:0,
      left:0,
      zIndex:10000000001,
      width: '100%',
      height: '100%'}}>
      <svg class="svgLoader" viewBox="0 0 100 100" width="12em" height="12em">
        <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
      </svg>
    </div>
);
class ResetPasswordFinal extends Component {
  constructor() {
    super();
    this.state={

      checked:false,

      password1:'',
      password2:'',
      loadingscreen:false,
        checkedValidated:true,


        p1:true,
        p2:true,
        p3:true,
        p4:true,
        p5:true,
        p6:true,
        p7:true,
        p8:true,
        p9:true,
        p10:false,
        isVerified:false,
        token:''



    }
  }
    verifyCallback=(response)=>
    {
        if(response)
            this.setState({
                isVerified:true
            })

    }
    expiredCallback=()=>
    {
        this.setState(
            {
                isVerified:false
            })
   }
  handleChange=(event)=>{
    this.setState({
      checked: event.target.checked
    })

  }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru-RU'
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {
        this._speech = new SpeechSynthesisUtterance();
        const params = new URLSearchParams(window.location.search)
        let token = params.get('token')
        this.setState({
            token:token
        })


    }

    signup=()=>{
    toast.configure();

       if (this.state.password1 === '') {
        toast.warning(this.props.t('parolniKiriting'))
        this.setState({
          p1:false
        })
        this.setState({
          loadingscreen:false
        })
      }
       else
       {
           this.setState({
               p1:true
           })
       }
       if(this.state.password1.length<8)
      {
        toast.warning(this.props.t('parolUzunligi'))
        this.setState({
          p2:false
        })
        this.setState({
          loadingscreen:false
        })
      }
       else {
           this.setState({
               p2:true
           })
       }
       if(this.state.password1.length>20)
      {
        toast.warning(this.props.t('parolJudaU'))
        this.setState({
          p3:false
        })
        this.setState({
          loadingscreen:false
        })
      }
       else {
           this.setState({
               p3:true
           })
       }
       if(this.state.password1.search(/\d/)===-1)
      {
        toast.warning(this.props.t('parolTalabi'))
        this.setState({
          p4:false
        })
        this.setState({
          loadingscreen:false
        })

      }
       else {
           this.setState({
               p4:true
           })
       }
       if(this.state.password1.search(/[A-Z]/)===-1)
      {
        toast.warning(this.props.t('parolTalabi'))
        this.setState({
          p5:false
        })
        this.setState({
          loadingscreen:false
        })

      }
       else {
           this.setState({
               p5:true
           })
       }
       if(this.state.password1.search(/\W/)===-1)
      {
        toast.warning(this.props.t('parolTalabi'))
        this.setState({
          p6:false
        })
        this.setState({
          loadingscreen:false
        })

      }
       else {
           this.setState({
               p6:true
           })
       }
       if(this.state.password1.search(/[a-z]/)===-1)
      {
        toast.warning(this.props.t('parolTalabi'))
        this.setState({
          p7:false
        })
        this.setState({
          loadingscreen:false
        })
      }
       else {
           this.setState({
               p7:true
           })
       }
       if (this.state.password2 === '') {
        toast.warning(this.props.t('parolniQaytaKiriting'))
        this.setState({
          p8:false
        })
        this.setState({
          loadingscreen:false
        })
      }
       else {
           this.setState({
               p8:true
           })
       }
       if (this.state.password1 !== this.state.password2) {
        toast.warning(this.props.t('parollarBXE'))
        this.setState({
          p9:false
        })
        this.setState({
          loadingscreen:false
        })
           }
       else {
           this.setState({
               p9:true
           })
       }
       if (this.state.password1 === this.state.password2) {
        this.setState({
          p10:true
        })
      }
       else {
           this.setState({
               p10:false
           })
       }

     if(((((
         (this.state.p1&&this.state.p2))&&
         (this.state.p3&&this.state.p4))&&
         (this.state.p5&&this.state.p6))&&
         (this.state.p7&&this.state.p8))&&
         (this.state.p9&&this.state.p10)
     )
      {
          if(this.state.isVerified)
          {
        this.setState({
          loadingscreen:true
        })
              let passwordResetForm={

                  "password":this.state.password1,
                  "token":this.state.token
              }
              AuthenticationService.resetPasswordFinal(passwordResetForm).then((res)=>{
                  toast.success("resetPS")
                  setTimeout(function () {
                      history.push("/")
                  },2000)
              }).catch((err)=>{

                      toast.warning(this.props.t("tokenExpired"))
                      setTimeout(function () {
                          history.push("/forgot-password")
                      },2000)

              })
      }else{
              toast.warning(this.props.t("captchaniT"))
          }}




  }
  render() {
    return (
        <React.Fragment>
          {this.state.loadingscreen&&<Loader/>
          }
          <div className="back-to-home rounded d-none d-sm-block">
            <Link
                to="/"
                className="btn btn-icon btn-soft-primary"
            >
              <i>
                <FeatherIcon icon="home" className="icons" />
              </i>
            </Link>
          </div>

          <section className="bg-home bg-circle-gradiant d-flex align-items-center">
            <div className="bg-overlay bg-overlay-white"></div>
            <Container>
              <Row className="justify-content-center">
                <Col lg={5} md={8}>
                  <Card className="login-page bg-white shadow rounded border-0">
                    <CardBody>
                      <h4 onClick={this.speak} className="card-title text-center">{this.props.t('parolniT')}</h4>
                      <AvForm className="login-form mt-4">
                        <Row>

                          <Col md={12}>
                            <FormGroup className="position-relative">
                              <div onClick={this.speak}><Label>
                                {this.props.t('parol')} <span className="text-danger">*</span>
                              </Label>
                              </div>
                              <div className="position-relative">
                                <i>
                                  <FeatherIcon
                                      icon="lock"
                                      className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField
                                  type="password"
                                  className="form-control pl-5"
                                  errorMessage= {this.props.t('parolniKiriting')}
                                  validate={{ required: { value: true } }}
                                  name="password1"
                                  placeholder="Password"
                                  onChange={(event)=>{this.setState({
                                    password1:event.target.value
                                  })}}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup className="position-relative">
                                <div onClick={this.speak}>
                              <Label>
                                {this.props.t('parolniQaytaKiriting')}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                                </div>
                              <div className="position-relative">
                                <i>
                                  <FeatherIcon
                                      icon="lock"
                                      className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField
                                  type="password"
                                  className="form-control pl-5"
                                  errorMessage={this.props.t('parolniQaytaKiriting')}
                                  validate={{
                                    required: { value: true }

                                  }}
                                  name="password2"
                                  placeholder="Confirm Password"
                                  onChange={(event)=>{this.setState({
                                    password2:event.target.value
                                  })}}
                              />

                            </FormGroup>
                          </Col>
                            <div onClick={this.speak} style={{marginLeft:"13px"}}>

                                <label className="f_p text_c f_400">CAPTCHA</label>
                               <ReCaptchaV2 sitekey={REACT_APP_SITE_KEY}
                                             onChange ={this.verifyCallback}
                                             onExpired={this.expiredCallback}
                                />
                            </div>

                          <Col lg={12} className="mb-0">
                            <Button color="primary"  onClick={this.signup}>
                              {this.props.t('parolniYangilash')}
                            </Button>
                          </Col>


                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </React.Fragment>
    );
  }
}

export default withTranslation()(ResetPasswordFinal);
