import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import bg_image from "../../assets/images/image.jpg";
import {withTranslation} from "react-i18next";
import Counter2 from "../../components/Shared/counter2";
import Asset190 from "../../assets/images/illustrator/1.png";
import a from "../../assets/images/illustrator/1.png"
import Asset192 from "../../assets/images/illustrator/1.png";
import {
    Form,
    Button,
    FormGroup,
} from "reactstrap";
import RoadTypeLengthService from "../../api/RoadTypeLengthService";
import i18next from "../../i18n";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import history from "../../history";


 class Section extends Component {


     constructor(props) {
         super(props);
         this.state = {
             international:'',
             state:'',
             local:'',
             counters: [
                 {
                     title: this.props.t('xalqaroAY'),
                     image: Asset190,
                     start: 0,
                     value: 0,
                     postfix: " км",

                 },
                 {
                     title: props.t('davlatAY'),
                     image: Asset192,
                     start: 0,
                     value: 0,
                     postfix: " км",

                 },
                 {
                     title: props.t('mahalliyAY'),
                     image: a,
                     start: 0,
                     value: 0,
                     postfix: " км",
                     size:'16px'
                 }
             ],
             searchKey:''
         };
     }


     componentDidMount() {
         this._speech = new SpeechSynthesisUtterance();

        RoadTypeLengthService.get().then((res)=>{
            this.setState({
                counters:[
                    {
                        title: this.props.t('xalqaroAY'),
                        image: Asset190,
                        start: 0,
                        value: res.data.international,
                        postfix: " км",

                    },
                    {
                        title:  this.props.t('davlatAY'),
                        image: Asset192,
                        start: 0,
                        value: res.data.state,
                        postfix: " км",

                    },
                    {
                        title:  this.props.t('mahalliyAY'),
                        image: a,
                        start: 0,
                        value: res.data.local,
                        postfix: " км",
                        size:'16px'
                    }
                ]
            })
        })
     }
     checkNotEmptyKey=()=>{
         toast.configure()
         if(this.state.searchKey==='')
             toast.warning(this.props.t("kalitSK"))
         else if(this.state.searchKey!=='')
             history.push({
                 pathname: '/search-results',
                 state: this.state.searchKey
             })
     }
     checkNotEmptyKey2=(event)=>{
         if (event.charCode === 13) {
         toast.configure()
         if(this.state.searchKey==='')
             toast.warning(this.props.t("kalitSK"))
         else if(this.state.searchKey!=='')
             history.push({
                 pathname: '/search-results',
                 state: this.state.searchKey
             })}
     }
     speak=(event)=>{
         if(window.isSpeakingEnabled)
         {
             this._speech.text =event.currentTarget.textContent;
             this._speech.lang = 'ru-RU'
             window.speechSynthesis.speak(this._speech);}
     }
     speak2=(x)=>{
         if(window.isSpeakingEnabled)
         {
             this._speech.text =x;
             this._speech.lang = 'ru-RU'
             window.speechSynthesis.speak(this._speech);}
     }
     render() {
    return (
      <React.Fragment>
        <section
          className="bg-half-260 d-table w-100"
          style={{ background: `url(${bg_image}) center center`,padding:"240px 0" , backgroundSize:"cover" }}
        >
          <div className="bg-overlay" style={{ opacity: "0.65" }}></div>
          <Container>
            <Row lg={2}>
                <Col lg={5}>
                    <div className="title-heading">
                        <h4 onClick={this.speak} className="display-4 mb-4 font-weight-bold text-white title-dark">
                            {this.props.t('unitarK1')}  <br />{this.props.t('unitarK2')}
                        </h4>
                        <p onClick={this.speak} className="para-desc text-white-50">
                            {this.props.t('unitarF')}
                            {/*Launch your campaign and benefit from our expertise on*/}
                            {/*designing and managing conversion centered bootstrap4 html*/}
                            {/*page.*/}
                        </p>
                        <div className="subcribe-form mt-4 pt-2" style={{maxWidth:"380px"}}>
                            <Form >
                                <FormGroup className="mb-0" >
                                    <input
                                        onClick={()=>this.speak2(this.props.t('kalitSK'))}
                                        onKeyPress={(event)=>this.checkNotEmptyKey2(event)}
                                        id="help"
                                        className="border bg-white rounded-pill shadow"
                                        required
                                        placeholder={this.props.t('kalitSK')}
                                        style={{paddingLeft:"10px",paddingRight:"110px"}}
                                        onChange={(event)=>this.setState({searchKey:event.target.value})}
                                    />


                                        <Button   className="btn btn-light btn-pills small font-weight-bold"
                                                  onClick={this.checkNotEmptyKey}
                                                  >
                                            {this.props.t('search')}
                                        </Button>

                                </FormGroup>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col lg={7}>
                    <Row lg={3} >
                        <Counter2 onClick={this.speak} counters={this.state.counters} />
                    </Row>
                </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Section)
