import React, {Component, useState} from 'react';
import { Document, Page } from 'react-pdf';
import PdfDocument from "./PdfDocument";
import GeneratePdfDocument from "./GeneratePdfDocument";

class ViewPDF extends Component
{
    constructor() {
        super();
        this.state={
            numPages:null,
            pageNumber:1
        }
    }
    onDocumentLoadSuccess=({ numPages })=> {

        this.setState({
            numPages:numPages
        })
    }
render() {
    return (
        <div style={{maxHeight: "600px",
            overflowY: "auto"}}>
           <PdfDocument/>
        </div>
    );
}


}
export default ViewPDF