
import {API_URL} from "../Constants";
import instance from "./api";

class DocFilesService {

    getDocFilesList(typ)
    {
        return instance.get(`${API_URL}/api/docfile/${typ}`,{
            withCredentials:true
        })
    }

    downloadFile(reportId)
    {
        return instance.post(`${API_URL}/api/filesdf/`,{
            withCredentials:true
        })
    }

}



export default new DocFilesService()
