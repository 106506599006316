import React, { Component } from "react";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

import {
  Container,
  Row,
  Col,
  Input,
  Card,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  FormGroup,
  Label, Progress,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

//Import Slick Slider
import Slider from "react-slick";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Slick Slider CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Import components
import PageBreadcrumb from "../../../components/Shared/PageBreadcrumb";

import client1 from "../../../assets/images/client/01.jpg";
import client2 from "../../../assets/images/client/02.jpg";

//Import Images
import product1 from "../../../assets/images/shop/product/s1.jpg";
import product2 from "../../../assets/images/shop/product/s2.jpg";
import product3 from "../../../assets/images/shop/product/s3.jpg";
import product4 from "../../../assets/images/shop/product/s4.jpg";
import product5 from "../../../assets/images/shop/product/s5.jpg";
import product6 from "../../../assets/images/shop/product/s6.jpg";
import product7 from "../../../assets/images/shop/product/s7.jpg";
import product8 from "../../../assets/images/shop/product/s8.jpg";

import prodtctOverlay1 from "../../../assets/images/shop/product/s-1.jpg";
import prodtctOverlay2 from "../../../assets/images/shop/product/s-2.jpg";
import prodtctOverlay3 from "../../../assets/images/shop/product/s-3.jpg";
import prodtctOverlay4 from "../../../assets/images/shop/product/s-4.jpg";
import prodtctOverlay5 from "../../../assets/images/shop/product/s-5.jpg";
import prodtctOverlay6 from "../../../assets/images/shop/product/s-6.jpg";
import prodtctOverlay7 from "../../../assets/images/shop/product/s-7.jpg";
import prodtctOverlay8 from "../../../assets/images/shop/product/s-8.jpg";

// import slider images
import single01 from "../../../assets/images/shop/product/single-1.jpg";
import single02 from "../../../assets/images/shop/product/single-2.jpg";
import single03 from "../../../assets/images/shop/product/single-3.jpg";
import single04 from "../../../assets/images/shop/product/single-4.jpg";
import single05 from "../../../assets/images/shop/product/single-5.jpg";
import single06 from "../../../assets/images/shop/product/single-6.jpg";
import ReactWeather, {useOpenWeather} from "react-open-weather";
import CurrencyRatesService from "../../../api/CurrencyRatesService";

class ShopProductDetail extends Component {
  constructor(props) {
    const {t}=props.translation
    super(props);
    this.state = {
      rate01:'',
      rate02:'',
      rate03:'',
      rate04:'',
      pathItems: [
        //id must required
        { id: 1, name: "Tomdi YMFUK", link: "/" },
        { id: 2, name: t('authority'), link: "/authority" },
        { id: 3, name: t('xavfs') },
      ],

      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 4,
        },
      },
      nav1: null,
      nav2: null,
      items: 1,
      activeTab: "1",
    };
    this.addItem.bind(this);
    this.removeItem.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  addItem = () => {
    this.setState({ items: this.state.items + 1 });
  };

  removeItem = () => {
    if (this.state.items > 1) {
      this.setState({ items: this.state.items - 1 });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
    CurrencyRatesService.getCUrrency().then((response) => {
      this.setState({
        currencyRates: response.data,
        rate01: response.data[0],
        rate02: response.data[1],
        rate03: response.data[2],
        rate04: response.data[3]
      })
    })
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  render() {
    var settings = {
      autoplay: true,
      infinite: true,
      autoplaySpeed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      draggable: true,
      pauseOnHover: true,
    };

    const settings2 = {
      dots: false,
      infinite: true,
      autoplaySpeed: 2000,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 2,
    };
    const {t}=this.props.translation
    const { data, isLoading, errorMessage }=this.props.weatherr
    return (
      <React.Fragment>
        {/* breadcrumb */}
        <PageBreadcrumb
          title={t('authority')}
          pathItems={this.state.pathItems}
        />
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row className="align-items-center">
              <Col md={4}>
                <div style={{width:"250px",height:"350px"}}
                >
                  <img
                      src={require('../../../xavfs.jpg').default}
                      className="img-fluid"
                      alt="Landrick"
                  />
                </div>
              </Col>

              <Col md={7} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title ml-md-4">
                  <h4 className="title">Базарбаев Нурсултан Шарипбекович</h4>
                  <h5 className="text-muted">
                  </h5>
                  <h5 className="mt-4 py-2">{t('xavfs')}</h5>


                </div>
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <Row>
              <Col lg="3" md="8" xs="12" className="d-lg-block d-none">
                <ReactWeather
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    data={data}
                    lang="ru"
                    locationLabel="Navoiy"
                    unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                    showForecast
                />
                <Card className="border-0 sidebar sticky-bar">

                  <CardBody className="p-0">

                    <div className="widget">

                      <div id="search2" className="widget-search mb-0">


                      </div>
                    </div>

                    <div className="widget mt-4 pt-2">
                      <h4 className="widget-title">{t('valyutaKurslari')} {this.state.rate01.Date} {t('holatiga')}</h4>
                      <ul className="list-unstyled mt-4 mb-0 blog-categories">
                        <li onClick={this.speak}>
                          {this.state.rate01.Nominal} {this.state.rate01.CcyNm_RU} = {this.state.rate01.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate02.Nominal} {this.state.rate02.CcyNm_RU} = {this.state.rate02.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate03.Nominal} {this.state.rate03.CcyNm_RU} = {this.state.rate03.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate03.Nominal} {this.state.rate04.CcyNm_RU} = {this.state.rate04.Rate} UZS
                        </li>

                      </ul>
                    </div>




                  </CardBody>
                </Card>
              </Col>
              <Col lg="8" md="7" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul className="nav nav-pills shadow flex-column flex-sm-row d-md-inline-flex mb-0 p-1 bg-white rounded position-relative overflow-hidden">
                  <NavItem className="m-1">
                    <NavLink
                      to="#"
                      className={classnames(
                        { active: this.state.activeTab === "1" },
                        "rounded py-2 px-5"
                      )}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <div className="text-center">
                        <h6 className="mb-0">{t('mehnatFaoliyati')} :</h6>
                      </div>
                    </NavLink>
                  </NavItem>


                </ul>

                <TabContent className="mt-5" activeTab={this.state.activeTab}>
                  <TabPane className="card border-0 fade show active" tabId="1">

                    <p className="text-dark mb-0" style={{fontSize:"20px"}}>
                      2013-2014 йй. – Томди туман йул хўжалиги пудрат таъмирлаш фойдаланиш корхонаси етакчи мутахассиси
                    </p>
                    <p className="text-dark mb-0" style={{fontSize:"20px"}}>
                      2015-2016 йй. - Томди туман йул хўжалиги пудрат таъмирлаш фойдаланиш корхонаси автомобил йулларидан фойдаланиш бўлими етакчи мутахассиси
                    </p>
                    <p className="text-dark mb-0" style={{fontSize:"20px"}}>
                      2016-2017 йй. - Томди туман йул хўжалиги пудрат таъмирлаш фойдаланиш корхонаси автомобил йулларидан фойдаланиш бўлими бошлиғи
                    </p>
                    <p className="text-dark mb-0" style={{fontSize:"20px"}}>
                      2017 й. -  ҳ.в.  – Томди тумани йуллардан фойдаланиш унитар корхонаси йул харакати хавфсизлигини таъминлаш бўлими бошлиғи
                    </p>



                  </TabPane>


                </TabContent>
              </Col>
            </Row>
          </Container>


        </section>
      </React.Fragment>
    );
  }
}

export default ()=>{

  const {t} = useTranslation();
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '6057277f00c3fed52889f1abe246f18d',
    lat: 40.105319569073735,
    lon: 65.37402492454045,
    lang: 'ru',
    unit: 'metric', // values are (metric, standard, imperial)
  });
  return (
      <ShopProductDetail translation={{t}} weatherr={{ data, isLoading, errorMessage }} />
  )
};
