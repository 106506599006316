import React, { Component } from "react";
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import components
import PageBreadcrumb from "../../../components/Shared/PageBreadcrumb";

//Import Images
import product1 from "../../../assets/images/shop/product/s1.jpg";
import product2 from "../../../assets/images/shop/product/s2.jpg";
import product3 from "../../../assets/images/shop/product/s3.jpg";
import product4 from "../../../assets/images/shop/product/s4.jpg";
import product5 from "../../../assets/images/shop/product/s5.jpg";
import product6 from "../../../assets/images/shop/product/s6.jpg";
import product7 from "../../../assets/images/shop/product/s7.jpg";
import product8 from "../../../assets/images/shop/product/s8.jpg";
import product9 from "../../../assets/images/shop/product/s9.jpg";
import product10 from "../../../assets/images/shop/product/s10.jpg";
import product11 from "../../../assets/images/shop/product/s11.jpg";
import product12 from "../../../assets/images/shop/product/s12.jpg";
import product13 from "../../../assets/images/shop/product/s13.jpg";
import product14 from "../../../assets/images/shop/product/s14.jpg";
import product15 from "../../../assets/images/shop/product/s15.jpg";

import prodtctOverlay1 from "../../../assets/images/shop/product/s-1.jpg";
import prodtctOverlay2 from "../../../assets/images/shop/product/s-2.jpg";
import prodtctOverlay3 from "../../../assets/images/shop/product/s-3.jpg";
import prodtctOverlay4 from "../../../assets/images/shop/product/s-4.jpg";
import prodtctOverlay5 from "../../../assets/images/shop/product/s-5.jpg";
import prodtctOverlay6 from "../../../assets/images/shop/product/s-6.jpg";
import prodtctOverlay7 from "../../../assets/images/shop/product/s-7.jpg";
import prodtctOverlay8 from "../../../assets/images/shop/product/s-8.jpg";
import prodtctOverlay9 from "../../../assets/images/shop/product/s-9.jpg";
import prodtctOverlay10 from "../../../assets/images/shop/product/s-10.jpg";
import prodtctOverlay11 from "../../../assets/images/shop/product/s-11.jpg";
import prodtctOverlay12 from "../../../assets/images/shop/product/s-12.jpg";
import prodtctOverlay13 from "../../../assets/images/shop/product/s-13.jpg";
import prodtctOverlay14 from "../../../assets/images/shop/product/s-14.jpg";
import prodtctOverlay15 from "../../../assets/images/shop/product/s-15.jpg";
import CurrencyRatesService from "../../../api/CurrencyRatesService";
import AuthorityService from "../../../api/AuthorityService";
import {API_URL} from "../../../Constants";
import AuthoritySearchService from "../../../api/AuthoritySearchService";
import history from "../../../history";

class AuthoritySearchResults extends Component {

  constructor(props) {
    const {t}=props.translation
    super(props);
    this.state = {
      pathItems: [

        { id: 1, name: t('unitarK'), link: "/" },
        { id: 2, name: t('qidiruvN'), link: "" },

      ],

      rate01:'',
      rate02:'',
      rate03:'',
      rate04:'',
      authorities:[],
      title: history.location.state+ ' ' +t('kalitSBQN')
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
    window.scrollTo({top:0,behavior:"smooth"})
    AuthoritySearchService.search(history.location.state).then((res)=>{
      this.setState({
        authorities:res.data
      })
    })

    CurrencyRatesService.getCUrrency().then((response) => {
      this.setState({
        currencyRates: response.data,
        rate01: response.data[0],
        rate02: response.data[1],
        rate03: response.data[2],
        rate04: response.data[3]
      })
    })
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);

  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  render() {
    const { data, isLoading, errorMessage }=this.props.weatherr
    const {t}=this.props.translation

    return (
      <React.Fragment>
        {/* breadcrumb */}

        <PageBreadcrumb title={this.state.title} pathItems={this.state.pathItems} />
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row>
              <Col lg={3} md={5} xs={12}>
                <ReactWeather
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    data={data}
                    lang="ru"
                    locationLabel="Navoiy"
                    unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                    showForecast
                />
                <Card className="border-0 sidebar sticky-bar">

                  <CardBody className="p-0">

                    <div className="widget">

                      <div id="search2" className="widget-search mb-0">


                      </div>
                    </div>

                    <div className="widget mt-4 pt-2">
                      <h4 className="widget-title">{t('valyutaKurslari')} {this.state.rate01.Date} {t('holatiga')}</h4>
                      <ul className="list-unstyled mt-4 mb-0 blog-categories">
                        <li onClick={this.speak}>
                          {this.state.rate01.Nominal} {this.state.rate01.CcyNm_RU} = {this.state.rate01.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate02.Nominal} {this.state.rate02.CcyNm_RU} = {this.state.rate02.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate03.Nominal} {this.state.rate03.CcyNm_RU} = {this.state.rate03.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate03.Nominal} {this.state.rate04.CcyNm_RU} = {this.state.rate04.Rate} UZS
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={9} md={8} xs={12} className="mt-5 pt-2 mt-sm-0 pt-sm-0">
                <Row>

                  {this.state.authorities.map((p,k)=>

                      <Col  lg={4} md={6} xs={12} className="mt-4 pt-2">
                    <Card  className="shop-list border-0 position-relative overflow-hidden">
                      <div className="shop-image position-relative overflow-hidden rounded shadow">
                        <Link to={{
                          pathname: `/principal`,
                          state:{p}
                        }}>
                          <img
                              src={`${API_URL}/api/images/${p.image}`}
                              className="img-fluid"
                              alt="Landrick"
                          />
                        </Link>

                      </div>
                      <CardBody className="content pt-4 p-2">
                        <Link to={{
                          pathname: `/principal`,
                          state:{p}
                        }}>
                          {p.name}{' '}{p.surname}{' '}{p.patronymic}
                        </Link>
                        <div className="d-flex justify-content-between mt-1">
                          <Link
                              to="principal"
                              className="text-blue product-name h6"
                          >
                            {p.position}
                          </Link>

                        </div>
                      </CardBody>
                    </Card>

                  </Col>)}



                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ()=>{

  const {t} = useTranslation();
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '6057277f00c3fed52889f1abe246f18d',
    lat: 40.105319569073735,
    lon: 65.37402492454045,
    lang: 'ru',
    unit: 'metric', // values are (metric, standard, imperial)
  });
  return (
      <AuthoritySearchResults translation={{t}} weatherr={{ data, isLoading, errorMessage }} />
  )
};
