import instance from "./api";
import {API_URL} from "../Constants";

class GalleryVideoService {

    getVideoPage(p,s)
    {
        return instance.get(`${API_URL}/api/videogallery/`,{params:{page:p,size:s}})
    }

}
export default new GalleryVideoService()
