import React, { Component } from "react";
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Form,FormGroup,Label,Input,Button} from 'reactstrap'
import Popup from 'reactjs-popup';
import { Link } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import components
import PageBreadcrumb from "../../../components/Shared/PageBreadcrumb";

//Import Images
import product1 from "../../../assets/images/shop/product/s1.jpg";
import product2 from "../../../assets/images/shop/product/s2.jpg";
import product3 from "../../../assets/images/shop/product/s3.jpg";
import product4 from "../../../assets/images/shop/product/s4.jpg";
import product5 from "../../../assets/images/shop/product/s5.jpg";
import product6 from "../../../assets/images/shop/product/s6.jpg";
import product7 from "../../../assets/images/shop/product/s7.jpg";
import product8 from "../../../assets/images/shop/product/s8.jpg";
import product9 from "../../../assets/images/shop/product/s9.jpg";
import product10 from "../../../assets/images/shop/product/s10.jpg";
import product11 from "../../../assets/images/shop/product/s11.jpg";
import product12 from "../../../assets/images/shop/product/s12.jpg";
import product13 from "../../../assets/images/shop/product/s13.jpg";
import product14 from "../../../assets/images/shop/product/s14.jpg";
import product15 from "../../../assets/images/shop/product/s15.jpg";

import prodtctOverlay1 from "../../../assets/images/shop/product/s-1.jpg";
import prodtctOverlay2 from "../../../assets/images/shop/product/s-2.jpg";
import prodtctOverlay3 from "../../../assets/images/shop/product/s-3.jpg";
import prodtctOverlay4 from "../../../assets/images/shop/product/s-4.jpg";
import prodtctOverlay5 from "../../../assets/images/shop/product/s-5.jpg";
import prodtctOverlay6 from "../../../assets/images/shop/product/s-6.jpg";
import prodtctOverlay7 from "../../../assets/images/shop/product/s-7.jpg";
import prodtctOverlay8 from "../../../assets/images/shop/product/s-8.jpg";
import prodtctOverlay9 from "../../../assets/images/shop/product/s-9.jpg";
import prodtctOverlay10 from "../../../assets/images/shop/product/s-10.jpg";
import prodtctOverlay11 from "../../../assets/images/shop/product/s-11.jpg";
import prodtctOverlay12 from "../../../assets/images/shop/product/s-12.jpg";
import prodtctOverlay13 from "../../../assets/images/shop/product/s-13.jpg";
import prodtctOverlay14 from "../../../assets/images/shop/product/s-14.jpg";
import prodtctOverlay15 from "../../../assets/images/shop/product/s-15.jpg";
import CurrencyRatesService from "../../../api/CurrencyRatesService";
import AuthorityService from "../../../api/AuthorityService";
import {API_URL} from "../../../Constants";
import AuthenticationService from "../../../api/AuthenticationService";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MessageService from "../../../api/MessageService";
import history from "../../../history";
class UserSetttings extends Component {

  constructor(props) {
    const {t}=props.translation
    super(props);
    this.state = {
      pathItems: [
        { id: 1, name: t('unitarK'), link: "/" },
        { id: 2, name: t('settings'), link: "" },
      ],
      rate01:'',
      rate02:'',
      rate03:'',
      rate04:'',

      user:[],
      firstName:'',
      lastName:'',
      tel:'',
      password:'',
      answeredmessages:[],
      allmessages:[]

    };
  }

  updateUser11=(event)=>{

    event.preventDefault()
    toast.configure()
    const {t}=this.props.translation

    if(this.state.firstName!=='')
      this.state.user.firstName=this.state.firstName
    if(this.state.lastName!=='')
      this.state.user.lastName=this.state.lastName
    if(this.state.tel!=='')
      this.state.user.tel=this.state.tel
    if(this.state.password!=='')
      this.state.user.password=this.state.password

    AuthenticationService.updateUser(this.state.user).then((res)=>{
      toast.success(t("userDUS"))
      setTimeout(function () {
      window.location.reload()
      },2000)
    })

  }

  componentDidMount() {
    toast.configure()
    AuthenticationService.check22().then((res)=>{

    })
    window.addEventListener("scroll", this.scrollNavigation, true);
    window.scrollTo({top:0,behavior:"smooth"})
    AuthenticationService.getUserDetails().then((res)=>{
      this.setState({
        user:res.data
      })

    })

    MessageService.getall().then((res)=>{
      this.setState({
        allmessages:res.data
      })
    })
    MessageService.getanswered().then((res)=>{
      this.setState({
        answeredmessages:res.data
      })
    })

    CurrencyRatesService.getCUrrency().then((response) => {
      this.setState({
        currencyRates: response.data,
        rate01: response.data[0],
        rate02: response.data[1],
        rate03: response.data[2],
        rate04: response.data[3]
      })
    })
    this._speech = new SpeechSynthesisUtterance();
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);

  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if(document.getElementById("topnav")!==null) {
      if (top > 80) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }
    }
  };
  speak=(event)=>{
    if(window.isSpeakingEnabled)
    {
      this._speech.text =event.currentTarget.textContent;
      this._speech.lang = 'ru-RU'
      window.speechSynthesis.speak(this._speech);}
  }

  gotoallmessages=()=>{
    history.push({
      pathname: '/user/messages/all',
      state: this.state.allmessages
    })
  }
  gotoansweredmessages=()=>{
    history.push({
      pathname: '/user/messages/answered',
      state: this.state.answeredmessages
    })
  }
  render() {
    const { data, isLoading, errorMessage }=this.props.weatherr
    const {t}=this.props.translation

    return (
      <React.Fragment>
        {/* breadcrumb */}

        <PageBreadcrumb title={t('settings')} pathItems={this.state.pathItems} />
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row>
              <Col lg={3} md={5} xs={12}>
                <div onClick={this.speak}><ReactWeather
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    data={data}
                    lang="ru"
                    locationLabel="Navoiy"
                    unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                    showForecast
                /></div>
                <Card className="border-0 sidebar sticky-bar">

                  <CardBody className="p-0">

                    <div className="widget">

                      <div id="search2" className="widget-search mb-0">


                      </div>
                    </div>

                    <div className="widget mt-4 pt-2">
                      <h4 className="widget-title">{t('valyutaKurslari')} {this.state.rate01.Date} {t('holatiga')}</h4>
                      <ul className="list-unstyled mt-4 mb-0 blog-categories">
                        <li onClick={this.speak}>
                          {this.state.rate01.Nominal} {this.state.rate01.CcyNm_RU} = {this.state.rate01.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate02.Nominal} {this.state.rate02.CcyNm_RU} = {this.state.rate02.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate03.Nominal} {this.state.rate03.CcyNm_RU} = {this.state.rate03.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate03.Nominal} {this.state.rate04.CcyNm_RU} = {this.state.rate04.Rate} UZS
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={9} md={8} xs={12} className="mt-5 pt-2 mt-sm-0 pt-sm-0">
                  <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">{t('userSettings')}</h2>
                  <Card >
                    <CardHeader>
                      <FormGroup>

                        <Label onClick={this.speak}  className="col-form-label">{t('leftMessages')}{this.state.allmessages.length}</Label>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <button className="btn btn-primary" type="submit" onClick={this.gotoallmessages} >{t('korish')}</button>

                      </FormGroup>
                      <FormGroup>

                        <Label onClick={this.speak}className="col-form-label">{t('answeredMessages')}{this.state.answeredmessages.length}</Label>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <button className="btn btn-primary" type="submit" onClick={this.gotoansweredmessages}>{t('korish')}</button>
                      </FormGroup>
                      <FormGroup>

                        <Label onClick={this.speak}className="col-form-label">{t('xabarWherePost')}</Label>


                      </FormGroup>
                    </CardHeader>
                    <CardBody>
                      <Form className="theme-form mega-form" style={{fontSize:"20px"}}>

                        <h6 onClick={this.speak}>{t('userSettings')}</h6>

                        <FormGroup>
                          <Label onClick={this.speak}className="col-form-label">{t('ism')}</Label>
                          <Input style={{fontSize:"100%",height:"100%"}}className="form-control" type="text" defaultValue={this.state.user.firstName} />
                        </FormGroup>
                        <FormGroup>
                          <Label onClick={this.speak}className="col-form-label">{t('familiya')}</Label>
                          <Input style={{fontSize:"100%",height:"100%"}}className="form-control" type="text" defaultValue={this.state.user.lastName} />
                        </FormGroup>
                        <FormGroup>
                          <Label onClick={this.speak} className="col-form-label">{t('eMailM')}</Label>
                          <Input style={{fontSize:"100%",height:"100%"}}className="form-control" type="email" defaultValue={this.state.user.email} />
                        </FormGroup>
                        <FormGroup>
                          <Label onClick={this.speak}className="col-form-label">{t('tel')}</Label>
                          <Input style={{fontSize:"100%",height:"100%"}}className="form-control" type="Number" defaultValue={this.state.user.tel} />
                        </FormGroup>
                        <hr className="mt-4 mb-4" />
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Popup  trigger={ <Button style={{fontSize:"100%",height:"100%"}} color="primary" className="mr-1">{t('ozgartirish')}</Button>} position="right center" modal nested>
                        <Container fluid={true}>
                          <Card style={{
                            maxHeight: 'calc(100vh - 210px)',
                            overflowY: 'auto',
                            width:"600px"

                          }}>
                            <Col xl="16">
                              <Form className="card">
                                <CardHeader>
                                  <div className="card-options">
                                    <a className="card-options-collapse" href="#javascript">
                                      <i className="fe fe-chevron-up"></i>
                                    </a>
                                    <a className="card-options-remove" href="#javascript">
                                      <i className="fe fe-x"></i>
                                    </a>
                                  </div>
                                </CardHeader>
                                <CardBody>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label onClick={this.speak} className="form-label">{t('ism')}</Label>
                                        <Input className="form-control" type="text" defaultValue={this.state.user.firstName} onChange={(event)=>this.setState({firstName:event.target.value})} />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label onClick={this.speak}className="form-label">{t('familiya')}</Label>
                                        <Input className="form-control" type="email" defaultValue={this.state.user.lastName} onChange={(event)=>this.setState({lastName:event.target.value})} />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label onClick={this.speak}className="form-label">{t('parol')}</Label>
                                        <Input className="form-control" type="text" placeholder={t("yangiParol")} onChange={(event)=>this.setState({password:event.target.value})} />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label onClick={this.speak}className="form-label">{t('tel')}</Label>
                                        <Input className="form-control" type="text" defaultValue={this.state.user.tel} onChange={(event)=>this.setState({tel:event.target.value})} />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                                <CardFooter className="text-right">
                                  <button className="btn btn-primary" type="submit" onClick={(event)=>this.updateUser11(event)}>{t('yangilash')}</button>
                                </CardFooter>
                              </Form>
                            </Col>
                          </Card>
                        </Container>
                      </Popup>
                    </CardFooter>
                  </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ()=>{

  const {t} = useTranslation();
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '6057277f00c3fed52889f1abe246f18d',
    lat: 40.105319569073735,
    lon: 65.37402492454045,
    lang: 'ru',
    unit: 'metric',
  });
  return (
      <UserSetttings translation={{t}} weatherr={{ data, isLoading, errorMessage }} />
  )
};
