import React, { Component } from "react";
import { Link } from "react-router-dom";
import {API_URL} from "../../../Constants";
import FileDownload from 'js-file-download'
import axios from "axios";
import download from 'download'
import { Document, Page } from 'react-pdf';
import CKEditor from 'ckeditor4-react';

import {
  Button, Card, CardBody, CardFooter, CardHeader,
  Col,
  Container,
  Form,
  FormGroup, Input, Label,
  Row,
  Table,
} from "reactstrap";

import history from "../../../history";
import {withTranslation} from "react-i18next";
import FeatherIcon from "feather-icons-react";
import CommonSidebar2 from "./CommonSidebar2";
import DocFilesService from "../../../api/DocFilesService";
import { promisify } from 'util';
import CommonSidebar7 from "./CommonSidebar7";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Popup from "reactjs-popup";
import MessageService from "../../../api/MessageService";

 class LeftMessages extends Component {

   constructor() {
     super();
     this.state={
       files:[],
       question:'',
       messages:[]
     }
   }
   speak=(event)=>{
     if(window.isSpeakingEnabled)
     {
       this._speech.text =event.currentTarget.textContent;
       this._speech.lang = 'ru-RU'
       window.speechSynthesis.speak(this._speech);}
   }
  componentDidMount() {
    this._speech = new SpeechSynthesisUtterance();
    window.scrollTo({top:0,behavior:"smooth"})
    window.addEventListener("scroll", this.scrollNavigation, true);
    MessageService.getall().then((res)=>{
      this.setState({
        messages:res.data
      })
    })

  }

   editMes=(m)=>{
     if(m.isAnswered)
       toast.warning(this.props.t("cannotEditAnswered"))
     else{

     }

   }
   deleteMes=(m)=>{
     toast.configure()

     if(m.answered) {
       toast.warning(this.props.t("cannotDeleteAnswered"))
     }
     else{
        MessageService.deleteMes(m).then((res)=>{
          toast.success(this.props.t('messageDeleteSuccess'))
          setTimeout(function () {
            history.push("/settings")
          },2000)
        })
     }
   }

   updateMes=(event,m)=>{
     event.preventDefault()
     m.question=this.state.question
      MessageService.updateMes(m).then((res)=>{
        toast.success(this.props.t('messageEditSuccsess'))
        setTimeout(function () {
        window.location.reload()
        },2000)
      })
   }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if(document.getElementById("topnav")!==null) {
      if (top > 80) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <section className="bg-half bg-light d-table w-100">
          <Container>
            <Row className="justify-content-center">
              <Col lg={12} className="text-center">
                <div className="page-next-level">
                  <h4 onClick={this.speak}className="title"> {this.props.t('korxonaU')} </h4>
                  <div className="page-next">
                    <nav aria-label="breadcrumb" className="d-inline-block">
                      <ul className="breadcrumb bg-white rounded shadow mb-0">
                        <li onClick={this.speak}className="breadcrumb-item">
                          <Link onClick={this.speak}to="/">{this.props.t('unitarK')}</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                          onClick={this.speak}
                        >
                          {this.props.t('korxonaU')}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <div className="container" style={{minWidth:"1400px"}}>
            <Row>
              <Col lg={9}>


                <div className="table-responsive bg-white shadow rounded mt-4">
                  <Table className="mb-0 table-center">
                    <thead className="bg-light">
                      <tr>
                        <th onClick={this.speak}scope="col" style={{ minWidth: "300px" }}>
                          ID
                        </th>
                        <th
                          scope="col"
                          className="text-center"
                          style={{ maxWidth: "150px" }}
                          onClick={this.speak}
                        >
                          {this.props.t('sana')}
                        </th>
                        <th
                            scope="col"
                            className="text-center"
                            style={{ maxWidth: "150px" }}

                        >
                        </th>
                        <th
                            scope="col"
                            className="text-center"
                            style={{ maxWidth: "150px" }}
                        >
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                    {this.state.messages.map((f,k)=><tr key={k}>
                      <td>
                        <div className="media">
                          <FeatherIcon  className="fea icon-ex-md"/>
                          <div className="content ml-3" style={{fontSize: "14px"}}>
                            <Link
                                to="#"
                                className="forum-title text-primary font-weight-bold"
                                onClick={this.speak}
                            >
                              {f.id}
                            </Link>
                            <p className="text-muted small mb-0 mt-2">

                            </p>
                          </div>
                        </div>
                      </td>
                      <td onClick={this.speak}
                           className="text-center small h6">{(new Date(f.updatedAt).toLocaleDateString())}{" "}{(new Date(f.updatedAt).toLocaleTimeString())}</td>

                      {f.answered&&<td onClick={this.speak}
                          className="text-center small h6"> {this.props.t('cannotDoAny')}</td>}
                      <td onClick={this.speak}className="text-center small">
                        {!f.answered&&<Popup trigger={<Button style={{fontSize: "100%", height: "100%"}} color="primary"
                                                 className="btn small btn-primary">{this.props.t('ozgartirish')}</Button>}
                                position="right center" modal nested>
                          <Container fluid={true}>
                            <Card style={{
                              maxHeight: 'calc(100vh - 210px)',
                              overflowY: 'auto',
                              width: "600px"

                            }}>
                              <Col xl="16">
                                <Form className="card">
                                  <CardHeader>
                                    <div className="card-options">
                                      <a className="card-options-collapse" href="#javascript">
                                        <i className="fe fe-chevron-up"></i>
                                      </a>
                                      <a className="card-options-remove" href="#javascript">
                                        <i className="fe fe-x"></i>
                                      </a>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col>
                                        <FormGroup>
                                          <Label>Content</Label>
                                          <CKEditor
                                              data={f.question}
                                              onChange={evt => this.setState({question: evt.editor.getData()})}
                                          />

                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                  <CardFooter className="text-right">
                                    <Button className="btn btn-primary"
                                            onClick={(event) => this.updateMes(event,f)}>{this.props.t('yangilash')}</Button>
                                  </CardFooter>
                                </Form>
                              </Col>
                            </Card>
                          </Container>
                        </Popup>}

                      </td>
                      {!f.answered&&<td onClick={this.speak} className="text-center small">
                        <button className="btn small  btn-danger"
                                style={{fontSize: "10px"}}
                                onClick={() => this.deleteMes(f)}>{this.props.t('ochirish')}</button>
                      </td>}
                    </tr>)}
                    </tbody>
                  </Table>
                </div>
              </Col>

              <CommonSidebar7 />
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default withTranslation()(LeftMessages)