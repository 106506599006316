import instance from "./api";
import {API_URL} from "../Constants";


class CurrencyRatesService {

    getCUrrency()
    {
        return instance.get(`${API_URL}/api/currency`)

    }}
export default new CurrencyRatesService()
