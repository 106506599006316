import React, { Component } from "react";
import Section from "./Section";
import Schedule from "../../../src/pages/Event/Schedule";
import i18next from "i18next";
import {createBrowserHistory} from "history";
import history from "../../history";
import {Container} from "reactstrap";
import LandrickTeam from "../Developer/LandrickTeam";
const historyForce=createBrowserHistory({
  forceRefresh: true
});

export default class SearchIndex extends Component {

  constructor(props) {
    super(props);


  }
  speak=(event)=>{
    if(window.isSpeakingEnabled)
    {
      this._speech.text =event.currentTarget.textContent;
      this._speech.lang = 'ru-RU'
      window.speechSynthesis.speak(this._speech);}
  }
  componentDidMount() {
    this._speech = new SpeechSynthesisUtterance();
    // document.body.classList = "";
    // document.getElementById("top-menu").classList.add("nav-light");
    // document.getElementById("buyButton").className = "btn btn-light";
    //
    window.scrollTo({top:0,behavior:"smooth"})
 window.addEventListener("scroll", this.scrollNavigation, true);
  }


  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
      document.getElementById("buyButton").className = "btn btn-primary";

    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
      document.getElementById("buyButton").className = "btn btn-light";

    }
  };

  render() {
    return (
        <React.Fragment>
          <section className="section">
            <Container>

              <LandrickTeam />
            </Container>
          </section>
        </React.Fragment>
    );
  }
}
