import {API_URL} from "../Constants";
import instance from "./api";
import i18next from "i18next";


class AuthorityService {

    getAll()
    {
        return instance.get(`${API_URL}/api/authority/`,{headers:{
            "Locale":i18next.language
            }})
    }

    getById(id)
    {
        return instance.get(`${API_URL}/api/authority/id/${id}`),{headers:{
        "Locale":i18next.language
    }}
    }

}
export default new AuthorityService()
