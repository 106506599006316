
import {API_URL} from "../Constants";
import instance from "./api";

class CommentService {

    makeComment(comment)
    {
        return instance.post(`${API_URL}/api/comment/`,comment,{
            withCredentials:true
        })
    }
    getByNews(newsId,page,size)
    {
        return instance.get(`${API_URL}/api/comment/${newsId}/?page=${page}&size=${size}`)
    }
}
export default new CommentService()
