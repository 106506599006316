import React, {Component, useEffect, useState} from "react";
import Section from "./Section";
import Schedule from "../../../src/pages/Event/Schedule";
import i18next from "i18next";
import {createBrowserHistory} from "history";
import history from "../../history";
import {Container, Row} from "reactstrap";
import LandrickTeam from "../Developer/LandrickTeam";
import PartVerifyRegisterToken from "../Developer/PartVerifyRegisterToken";
import queryString from 'query-string'
import AuthenticationService from "../../api/AuthenticationService";
import {useTranslation} from "react-i18next";
const historyForce=createBrowserHistory({
  forceRefresh: true
});

const VerifyRegisterToken=()=> {
    const { t, i18n } = useTranslation();
    const [success,setsuccess]=useState(false)
    const [failure,setfailure]=useState(true)
    const _speech = new SpeechSynthesisUtterance();
  const speak=(event)=>{
    if(window.isSpeakingEnabled)
    {
      _speech.text =event.currentTarget.textContent;
      _speech.lang = 'ru-RU'
      window.speechSynthesis.speak(this._speech);}
  }
  useEffect (()=> {
          const params = new URLSearchParams(window.location.search)
          let token = params.get('token')
      AuthenticationService.verify(token).then((res)=>{
          setsuccess(true)
          setfailure(false)
      })

  }
, [])
  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
      document.getElementById("buyButton").className = "btn btn-primary";

    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
      document.getElementById("buyButton").className = "btn btn-light";

    }
  };


    return (
        <React.Fragment>
            <Container className="mt-100 mt-60">
                <Row className="justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title">
                            {success&&<h4 onClick={speak} className="title mb-4">
                                {t("signUpSuccess")}
                            </h4>}
                            {failure&&<h4 onClick={speak} className="title mb-4">
                                {t("signUpError")}
                            </h4>}
                        </div>
                    </div>
                </Row>
            </Container>
        </React.Fragment>
    );

}
export default VerifyRegisterToken