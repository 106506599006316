import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";
//Import Icons
import FeatherIcon from "feather-icons-react";
//Import Images
import logolight from "../../../assets/images/logo-light.png";
import logodark from "../../../assets/images/logo-dark.png";
import bgimg from "../../../assets/images/blog/workprocess.jpg";
// import blog1 from "../../../assets/images/zavod.jpg";
import client1 from "../../../assets/images/client/01.jpg";
import client2 from "../../../assets/images/client/02.jpg";
import client3 from "../../../assets/images/client/03.jpg";
import GalleryJobPictureService from "../../../api/GalleryJobPictureService";
import history from "../../../history";
import {API_URL} from "../../../Constants";

 class PageBlogDetailTwo extends Component {
   constructor() {
     super();
     this.state={
       pictures:[],
       description:''
     }
   }
   speak=(event)=>{
     if(window.isSpeakingEnabled)
     {
       this._speech.text =event.currentTarget.textContent;
       this._speech.lang = 'ru-RU'
       window.speechSynthesis.speak(this._speech);}
   }
  componentDidMount() {
    this._speech = new SpeechSynthesisUtterance();
    // document.body.classList = "";
    // document.getElementById("top-menu").classList.add("nav-light");
    // document.getElementById("buyButton").className = "btn btn-light";
    window.scrollTo({top:0,behavior:"smooth"})
    window.addEventListener("scroll", this.scrollNavigation, true);
    GalleryJobPictureService.getFamilyPictures(history.location.state.blog.family).then((res)=>{
      this.setState({
        pictures:res.data,
        description:res.data[0].description
      })

    })
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
      document.getElementById("buyButton").className = "btn btn-primary";

    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
      document.getElementById("buyButton").className = "btn btn-light";

    }
  };
  render() {
    return (
      <React.Fragment>
        <section
          className="bg-half d-table w-100"
          style={{ background: `url(${bgimg}) center ` }}
        >
          <div className="bg-overlay"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg={12} className="text-center">
                <div className="page-next-level">
                  <h4 onClick={this.speak} className="title text-white title-dark">
                    {this.props.t('ishJ')}
                  </h4>
                  <div className="page-next">
                    <nav aria-label="breadcrumb" className="d-inline-block">
                      <ul className="breadcrumb bg-white rounded shadow mb-0">
                        <li onClick={this.speak}className="breadcrumb-item">
                          <Link onClick={this.speak}to="/">{this.props.t('unitarK')}</Link>
                        </li>
                        <li onClick={this.speak} className="breadcrumb-item">
                          <Link onClick={this.speak}to="#">{this.props.t('gallery')}</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                          onClick={this.speak}
                        >
                          {this.props.t('ishJ')}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row>
                  <Col md={2} className="d-none d-md-block">
                    <ul className="list-unstyled text-center sticky-bar social-icon mb-0">
                      <li onClick={this.speak}className="mb-3 h6">{this.props.t('ulashish')}</li>
                      <li>
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                    </ul>
                  </Col>

                  <div className="col-md-10">
                    <p onClick={this.speak}className="text-muted">
                      {this.state.description}
                    </p>
                    {this.state.pictures.map((pp,key)=><img
                        key={key}
                        src={`${API_URL}/api/images/${pp.image}`}
                        className="img-fluid rounded-md shadow"
                        alt=""
                    />)}
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default withTranslation()(PageBlogDetailTwo)
