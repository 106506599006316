import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Card, CardBody } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";
import {withTranslation} from "react-i18next";

class PageSearchSidebar extends Component {
  render() {
    return (
      <React.Fragment>
        <Card className="border-0 sidebar sticky-bar rounded shadow">
          <CardBody>
            <div className="widget mb-4 pb-2">
              <h5 className="widget-title">{this.props.t('qidiruv')}</h5>
              <div id="search2" className="widget-search mt-4 mb-0">
                <Form
                  role="search"
                  method="get"
                  id="searchform"
                  className="searchform"
                >
                  <div>
                    <Input
                      type="text"
                      className="border rounded"
                      name="s"
                      id="s"
                      placeholder="..."
                    />
                    <Input type="submit" id="searchsubmit" value="Search" />
                  </div>
                </Form>
              </div>
            </div>

            <div className="widget mb-4 pb-2">
              <h5 className="widget-title">{this.props.t('kategoriyalar')}</h5>
              <ul className="list-unstyled mt-4 mb-0 blog-categories">
                <li>
                  <Link to="#">kategoriya01</Link>{" "}
                  <span className="float-right">13</span>
                </li>
                <li>
                  <Link to="#">kategoriya02</Link>{" "}
                  <span className="float-right">09</span>
                </li>
                <li>
                  <Link to="#">kategoriya03</Link>{" "}
                  <span className="float-right">18</span>
                </li>
                <li>
                  <Link to="#">kategoriya04</Link>{" "}
                  <span className="float-right">20</span>
                </li>
                <li>
                  <Link to="#">kategoriya05</Link>{" "}
                  <span className="float-right">22</span>
                </li>
              </ul>
            </div>

            {/*<div className="widget mb-4 pb-2">*/}
            {/*  <h5 className="widget-title">Recent Post</h5>*/}
            {/*  <div className="mt-4">*/}
            {/*    <div className="clearfix post-recent">*/}
            {/*      <div className="post-recent-thumb float-left">*/}
            {/*        {" "}*/}
            {/*        <Link to="#">*/}
            {/*          {" "}*/}
            {/*          <img*/}
            {/*            alt="img"*/}
            {/*            src={this.props.blog07}*/}
            {/*            className="img-fluid rounded"*/}
            {/*          />*/}
            {/*        </Link>*/}
            {/*      </div>*/}
            {/*      <div className="post-recent-content float-left">*/}
            {/*        <Link to="#">Consultant Business</Link>*/}
            {/*        <span className="text-muted mt-2">15th June, 2019</span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="clearfix post-recent">*/}
            {/*      <div className="post-recent-thumb float-left">*/}
            {/*        {" "}*/}
            {/*        <Link to="#">*/}
            {/*          {" "}*/}
            {/*          <img*/}
            {/*            alt="img"*/}
            {/*            src={this.props.blog08}*/}
            {/*            className="img-fluid rounded"*/}
            {/*          />*/}
            {/*        </Link>*/}
            {/*      </div>*/}
            {/*      <div className="post-recent-content float-left">*/}
            {/*        <Link to="#">Look On The Glorious Balance</Link>{" "}*/}
            {/*        <span className="text-muted mt-2">15th June, 2019</span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="clearfix post-recent">*/}
            {/*      <div className="post-recent-thumb float-left">*/}
            {/*        {" "}*/}
            {/*        <Link to="#">*/}
            {/*          {" "}*/}
            {/*          <img*/}
            {/*            alt="img"*/}
            {/*            src={this.props.blog01}*/}
            {/*            className="img-fluid rounded"*/}
            {/*          />*/}
            {/*        </Link>*/}
            {/*      </div>*/}
            {/*      <div className="post-recent-content float-left">*/}
            {/*        <Link to="#">Research Financial.</Link>{" "}*/}
            {/*        <span className="text-muted mt-2">15th June, 2019</span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="widget mb-4 pb-2">
              <h5 className="widget-title">{this.props.t('teglar')}</h5>
              <div className="tagcloud mt-4">
                <Link to="#" className="rounded">
                  teg01
                </Link>
                <Link to="#" className="rounded ml-1">
                  teg02
                </Link>
                <Link to="#" className="rounded ml-1">
                  teg03
                </Link>
                <Link to="#" className="rounded">
                  teg04
                </Link>
                <Link to="#" className="rounded ml-1">
                  teg05
                </Link>
                <Link to="#" className="rounded ml-1">
                  teg06
                </Link>
                <Link to="#" className="rounded">
                  teg07
                </Link>
                <Link to="#" className="rounded ml-1">
                  teg08
                </Link>
                <Link to="#" className="rounded ml-1">
                  teg09
                </Link>
                <Link to="#" className="rounded ml-1">
                  teg10
                </Link>
              </div>
            </div>

            <div className="widget">
              <h5 className="widget-title">{this.props.t('aloqadaBoling')}</h5>
              <ul className="list-unstyled social-icon mb-0 mt-4">
                <li className="list-inline-item ml-1">
                  <Link to="#" className="rounded">
                    <i>
                      <FeatherIcon
                        icon="facebook"
                        className="fea icon-sm fea-social"
                      />
                    </i>
                  </Link>
                </li>
                <li className="list-inline-item ml-1">
                  <Link to="#" className="rounded">
                    <i>
                      <FeatherIcon
                        icon="instagram"
                        className="fea icon-sm fea-social"
                      />
                    </i>
                  </Link>
                </li>
                <li className="list-inline-item ml-1">
                  <Link to="#" className="rounded">
                    <i>
                      <FeatherIcon
                        icon="twitter"
                        className="fea icon-sm fea-social"
                      />
                    </i>
                  </Link>
                </li>
                <li className="list-inline-item ml-1">
                  <Link to="#" className="rounded">
                    <i>
                      <FeatherIcon
                        icon="linkedin"
                        className="fea icon-sm fea-social"
                      />
                    </i>
                  </Link>
                </li>
                <li className="list-inline-item ml-1">
                  <Link to="#" className="rounded">
                    <i>
                      <FeatherIcon
                        icon="github"
                        className="fea icon-sm fea-social"
                      />
                    </i>
                  </Link>
                </li>
                <li className="list-inline-item ml-1">
                  <Link to="#" className="rounded">
                    <i>
                      <FeatherIcon
                        icon="youtube"
                        className="fea icon-sm fea-social"
                      />
                    </i>
                  </Link>
                </li>
                <li className="list-inline-item ml-1">
                  <Link to="#" className="rounded">
                    <i>
                      <FeatherIcon
                        icon="gitlab"
                        className="fea icon-sm fea-social"
                      />
                    </i>
                  </Link>
                </li>
              </ul>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default withTranslation()(PageSearchSidebar);
