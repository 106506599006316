import {API_URL} from "../Constants";
import instance from "./api";
import i18next from "i18next";

class DocFilesSearchService {

    search(key,page,size)
    {
        return instance.get(`${API_URL}/api/search/docfile/?key=${key}&page=${page}&size=${size}`,{
            headers:{
                "Locale":i18next.language
            }
        })
    }

    getTotalCount(key)
    {
        return instance.get(`${API_URL}/api/search/docfile/count/?key=${key}`,{
            headers:{
                "Locale":i18next.language
            }
        })
    }

}
export default new DocFilesSearchService()
