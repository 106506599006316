import {API_URL} from "../Constants";
import instance from "./api";


class RoadTypeLengthService {

    get()
    {
        return instance.get(`${API_URL}/api/roadtypelength/`)
    }

   }
export default new RoadTypeLengthService()
