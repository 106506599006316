import instance from './api';
import {createBrowserHistory} from "history";
import {API_URL} from "../Constants";


class AuthenticationService {

    signup(user)
    {
        return instance.post(`${API_URL}/api/auth/signup/`,user)
    }

    verify(token)
    {
        return instance.get(`${API_URL}/api/auth/verify/${token}`)
    }

    logout(){

        return instance.get(`${API_URL}/api/auth/logout/`,{
            withCredentials:true
        })

    }
    signin(user)
    {
        return  instance.post(`${API_URL}/api/auth/signin/`,user,{
            withCredentials: true
        })
    }
    updateUser(user)
    {
        return instance.post(`${API_URL}/api/auth/userdetails/`,user,{
            withCredentials:true
        })
    }
    resetPassword(email)
    {
        return instance.get(`${API_URL}/api/auth/passwordreset/toemail/${email}`)
    }

    getUserDetails()
    {
        return instance.get(`${API_URL}/api/auth/userdetails/`,{
            withCredentials:true
        })
    }

    resetPasswordFinal(resetpasswordform)
    {
        return instance.post(`${API_URL}/api/auth/passwordreset/verify/`,resetpasswordform)
    }

    refreshAccessToken()
    {
        return instance.get(`${API_URL}/api/auth/refreshtoken/`,{
            withCredentials:true
        })
    }
    check()
    {
        return instance.get(`${API_URL}/api/auth/check/`,{
            withCredentials:true
        })
    }
    check22()
    {
        return instance.get(`${API_URL}/api/auth/check2/`,{
            withCredentials:true
        })
    }


}

export default new AuthenticationService()
