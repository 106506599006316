import React, { Component } from "react";

import { Link } from "react-router-dom";
import {Card, CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";
import {useTranslation, withTranslation} from "react-i18next";
import ReactWeather, {useOpenWeather} from "react-open-weather";
import CurrencyRatesService from "../../../api/CurrencyRatesService";
import i18next from "i18next";

class CommonSidebar extends Component {
  constructor() {
    super();
    this.state={
      rate01:'',
      rate02:'',
      rate03:'',
      rate04:''
    }
  }
  speak=(event)=>{
    if(window.isSpeakingEnabled)
    {
      this._speech.text =event.currentTarget.textContent;
      this._speech.lang = 'ru-RU'
      window.speechSynthesis.speak(this._speech);}
  }
  componentDidMount() {
    this._speech = new SpeechSynthesisUtterance();
    CurrencyRatesService.getCUrrency().then((response) => {
      this.setState({
        currencyRates: response.data,
        rate01: response.data[0],
        rate02: response.data[1],
        rate03: response.data[2],
        rate04: response.data[3]
      })
    })
  }
  render() {
    const { data, isLoading, errorMessage }=this.props.weatherr
    const {t}=this.props.translation
    return (
      <React.Fragment>
        <div style={{width:"350px"}} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
          <Card className="border-0 sidebar sticky-bar rounded shadow bg-light">
            <CardBody>
              <div className="widget mb-4 pb-2">

              </div>


              <div className="widget mb-4 pb-2">
                <h5 onClick={this.speak}className="widget-title">{t('hisobotlar')}</h5>
                <ul className="list-unstyled mb-0 mt-4">
                  <li onClick={this.speak} className="text-muted small h6">

                    <Link onClick={this.speak} to="/balancesh" className="text-dark ml-2">
                      {t('buxgalteriyaB')}
                    </Link>{" "}
                  </li>
                  <li onClick={this.speak}className="text-muted small h6">

                    <Link onClick={this.speak}to="/financialr" className="text-dark ml-2">
                      {t('moliyaviyN')}
                    </Link>{" "}
                  </li>
                  <li onClick={this.speak}className="text-muted small h6">

                    <Link onClick={this.speak}to="/purchases" className="text-dark ml-2">
                      {t('xaridlarTM')}
                    </Link>{" "}
                  </li>
                  <li onClick={this.speak}className="text-muted small h6">

                    <Link onClick={this.speak}to="/evaulationr" className="text-dark ml-2">
                      {t('korxonaFBN')}
                    </Link>{" "}
                  </li>
                  <li onClick={this.speak}className="text-muted small h6">

                    <Link onClick={this.speak}to="/affinshbb" className="text-dark ml-2">
                      {t('affinlanganSBTB')}
                    </Link>{" "}
                  </li>
                  <li onClick={this.speak}className="text-muted small h6">

                    <Link onClick={this.speak}to="/larged" className="text-dark ml-2">
                      {t('yirikB')}
                    </Link>{" "}
                  </li>
                  <li onClick={this.speak}className="text-muted small h6">

                    <Link onClick={this.speak}to="/dividend" className="text-dark ml-2">
                      {t('hisoblanganVTD')}
                    </Link>{" "}
                  </li>


                </ul>
              </div>
              <div onClick={this.speak}>
              <ReactWeather
                  isLoading={isLoading}
                  errorMessage={errorMessage}
                  data={data}
                  lang="ru"
                  locationLabel="Navoiy"
                  unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                  showForecast
              />
              </div>

                    <div className="widget mt-4 pt-2">
                      <h4 onClick={this.speak}className="widget-title">{t('valyutaKurslari')} {this.state.rate01.Date} {t('holatiga')}</h4>
                      <ul className="list-unstyled mt-4 mb-0 blog-categories">
                        <li onClick={this.speak}>
                        {this.state.rate01.Nominal} {this.state.rate01.CcyNm_RU} = {this.state.rate01.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate02.Nominal} {this.state.rate02.CcyNm_RU} = {this.state.rate02.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate03.Nominal} {this.state.rate03.CcyNm_RU} = {this.state.rate03.Rate} UZS
                        </li>
                        <li onClick={this.speak}>
                          {this.state.rate03.Nominal} {this.state.rate04.CcyNm_RU} = {this.state.rate04.Rate} UZS
                        </li>

                      </ul>
                    </div>
              <div className="widget" style={{marginTop:"15px"}}>
                <h5 onClick={this.speak}className="widget-title">{t('bizniKB')}</h5>
                <ul className="list-unstyled social-icon social mb-0 mt-4">
                  <li className="list-inline-item mr-1">
                    <Link to="#" className="rounded">
                      <FeatherIcon
                        icon="facebook"
                        className="fea icon-sm fea-social"
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Link to="#" className="rounded">
                      <FeatherIcon
                        icon="instagram"
                        className="fea icon-sm fea-social"
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Link to="#" className="rounded">
                      <FeatherIcon
                        icon="twitter"
                        className="fea icon-sm fea-social"
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Link to="#" className="rounded">
                      <FeatherIcon
                        icon="linkedin"
                        className="fea icon-sm fea-social"
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Link to="#" className="rounded">
                      <FeatherIcon
                        icon="github"
                        className="fea icon-sm fea-social"
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Link to="#" className="rounded">
                      <FeatherIcon
                        icon="youtube"
                        className="fea icon-sm fea-social"
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Link to="#" className="rounded">
                      <FeatherIcon
                        icon="gitlab"
                        className="fea icon-sm fea-social"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
export default ()=>{

  const {t} = useTranslation();
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '6057277f00c3fed52889f1abe246f18d',
    lat: 40.105319569073735,
    lon: 65.37402492454045,
    lang: 'ru',
    unit: 'metric', // values are (metric, standard, imperial)
  });
  return (
      <CommonSidebar translation={{t}} weatherr={{ data, isLoading, errorMessage }} />
  )
};