import React, { Component,useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Person from "@material-ui/icons/Person";
import Search from "@material-ui/icons/Search";
import Vision from "@material-ui/icons/VisibilityOff";
import Microphone from "@material-ui/icons/Mic"
import Flags from 'country-flag-icons/react/3x2'
import {useTranslation} from "react-i18next";
import House from "@material-ui/icons/House"
import Settings from "@material-ui/icons/Settings"
import LogOut from "../../logout.svg"

import { withTranslation } from 'react-i18next';
import {
  Container,
  Form,
  Modal,
  ModalBody,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import shop1 from "../../assets/images/shop/product/s-1.jpg";
import shop2 from "../../assets/images/shop/product/s-2.jpg";
import shop3 from "../../assets/images/shop/product/s-3.jpg";
import NavbarButtons from "../Shared/NavbarButtons";
import i18next from "i18next";
import ReactDOM from 'react-dom'
import AuthenticationService from "../../api/AuthenticationService";
class Topbar2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpenShop: false,
      navLinks: [
        //Note : each child and nested child must have unique id
        {
          id: 1,
          title: <House fontSize="small"/>,
          link: "/"
        },

        {
          id: 11,
          title: props.t('korxonaH'),
          link: "/#",

          isOpenSubMenu: false,
          child: [
            { title: props.t('aboutUs'), link: "/page-aboutus-two" },
            { title: props.t('news'), link: "/news" },
            { title: props.t('authority'), link: "/authority" },
            { title: props.t('ustavFVUT'), link: "/ustavd" },
            { title: props.t('korxonaU'), link: "/ustav" },
            { title: props.t('korxonaTT'), link: "/structure" },
            { title: props.t('bushIU'), link: "/vacancy" },
            { title: props.t('affilanganSR'), link: "/affinshr" },
            { title: props.t('nizomlari'), link: "/documents" },
            { title: props.t('muhimF'), link: "/importantf" },


          ],
        },
        {
          id: 21,
          title: props.t('korxonaF'),
          link: "/#",

          isOpenSubMenu: false,
          child: [
            { title: props.t('biznesR'), link: "/business-plan" },
            { title: props.t('faoliyatiningAYVM'), link: "/cfields" },
            { title: props.t('bajariladiganI'), link: "/tasks" },

          ],
        },
        {
          id: 31,
          title: props.t('hisobotlar'),
          link: "/#",

          isOpenSubMenu: false,
          child: [

            { title: props.t('buxgalteriyaB'), link: "/balancesh" },
            { title: props.t('moliyaviyN'), link: "/financialr" },
            { title: props.t('xaridlarTM'), link: "/purchases" },
            { title: props.t('umumiyYQQQ'), link: "/generalmdec" },
            { title: props.t('korxonaFBN'), link: "/evaulationr" },
            { title: props.t('affinlanganSBTB'), link: "/affinshbb" },
            { title: props.t('yirikB'), link: "/larged" },
            { title: props.t('hisoblanganVTD'), link: "/dividend" },

          ],
        },
        {
          id: 3,
          title: props.t('gallery'),
          link: "/#",
          isOpenSubMenu: false,
          child: [
            {
              id: 4,
              title: props.t('fotoGalereya'),
              link: "/fotogallery",
              isOpenNestedSubMenu: false,
              nestedChild: [
                { title: props.t('ishJ'), link: "/page-blog-grid" },
                { title: props.t('zavodCh'), link: "/zavod1" },
                { title: props.t('zavodA'), link: "/zavod2" },
                { title: props.t('zavodX'), link: "/zavod3" },
                { title: props.t('zavodB'), link: "/zavod4" },

              ],
            },

            { title: props.t('videoGalereya'), link: "/video-gallery" },

          ],
        },

        {id:41,
          title: props.t('contacts'),
          link: "/page-contact-two",},
        {
          id: 7,
          title: <Search />,
          link: "/search",
          isOpenSubMenu: false,
          // child: [
          //   { title: "Contact Detail", link: "/page-contact-detail" },
          //   // { title: "Contact One", link: "/page-contact-one" },
          //   { title: "Contact", link: "/page-contact-two" },
          //   // { title: "Contact Three", link: "/page-contact-three" },
          // ],
        },
        // {
        //   id: 99,
        //   title: <Vision />,
        //   link: "/search",
        //   isOpenSubMenu: false,
        //
        //   // child: [
        //   //   { title: "Contact Detail", link: "/page-contact-detail" },
        //   //   // { title: "Contact One", link: "/page-contact-one" },
        //   //   { title: "Contact", link: "/page-contact-two" },
        //   //   // { title: "Contact Three", link: "/page-contact-three" },
        //   // ],
        // },

      ],
      wishlistModal: false,
      dropdownIsOpen: false,
      isClicked:true,
      loggedIn:false
    };
    this.toggleLine = this.toggleLine.bind(this);
    this.openBlock.bind(this);
    this.openNestedBlock.bind(this);
    this.toggleDropdownShop.bind(this);
    this.toggleWishlistModal.bind(this);
    this.toggleDropdownIsOpen.bind(this);
  }
  toggleWishlistModal = () => {
    this.setState((prevState) => ({
      wishlistModal: !prevState.wishlistModal,
    }));
  };

  toggleDropdownShop = () => {
    this.setState({
      dropdownOpenShop: !this.state.dropdownOpenShop,
    });
  };
  toggleDropdownIsOpen = () => {
    this.setState({
      dropdownIsOpen: !this.state.dropdownIsOpen,
    });
  };

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  componentDidMount() {
    AuthenticationService.check().then((res)=>
    {
      this.setState({
        loggedIn:true
      })

    })
    var matchingMenuItem = null;
    var ul = document.getElementById("top-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
    this._speech = new SpeechSynthesisUtterance();
  }
  signout=()=>
  {
    AuthenticationService.logout().then((res)=>{

      window.location.reload()
    })
  }
  activateParentDropdown = (item) => {
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent1 = parent.parentElement;
      parent1.classList.add("active"); // li
      if (parent1) {
        const parent2 = parent1.parentElement;
        parent2.classList.add("active"); // li
        if (parent2) {
          const parent3 = parent2.parentElement;
          parent3.classList.add("active"); // li
          if (parent3) {
            const parent4 = parent3.parentElement;
            parent4.classList.add("active"); // li
          }
        }
      }
    }
  };
  openBlock = (level2_id) => {
    var tmpLinks = this.state.navLinks;
    tmpLinks.map((tmpLink) =>
      //Match level 2 id
      tmpLink.id === level2_id
        ? (tmpLink.isOpenSubMenu = !tmpLink.isOpenSubMenu)
        : false
    );
    this.setState({ navLinks: tmpLinks });
  };

  openNestedBlock = (level2_id, level3_id) => {
    var tmpLinks = this.state.navLinks;
    tmpLinks.map((tmpLink) =>
      //Match level 2 id
      tmpLink.id === level2_id
        ? tmpLink.child.map((tmpchild) =>
            //if level1 id is matched then match level 3 id
            tmpchild.id === level3_id
              ? //if id is matched then update status(level 3 sub menu will be open)
                (tmpchild.isOpenNestedSubMenu = !tmpchild.isOpenNestedSubMenu)
              : (tmpchild.isOpenNestedSubMenu = false)
          )
        : false
    );
    this.setState({ navLinks: tmpLinks });
  };
  changeSiteLanguageToRu=()=>{
    i18next.changeLanguage('ru')
    setTimeout(function(){window.location.reload()});
  }
  changeSiteLanguageToKirill=()=>{
    i18next.changeLanguage('kirill')
    setTimeout(function(){window.location.reload()});
  }
  changeSiteLanguageToUz=()=>{
    i18next.changeLanguage('uz')
    setTimeout(function(){window.location.reload()});
  }
  changetogray=(event)=>{
    event.preventDefault()
    this.setState({
      isClicked:!this.state.isClicked
    })

    var list = document.getElementsByClassName('container')

    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByTagName('p')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }

    list = document.getElementsByClassName('item')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('grey')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }

    list = document.getElementsByClassName('btn')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }

    list = document.getElementsByClassName('grey')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('new_footer_area')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('breadcrumb_area')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }

    list = document.getElementsByClassName('widget')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('table-responsive')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }

    list = document.getElementsByClassName('bg-half-260')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }

    list = document.getElementsByClassName('btn')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }

    list = document.getElementsByClassName('btn-light')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('small')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName("btn btn-light  small")
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName("card-body")
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName("footer")
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName("MuiSvgIcon-root")
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName("logo")
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('counter-box')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('display-4')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('row')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }

    list = document.getElementsByClassName('collapse')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('navbar')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('slick-list')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('slick-dots')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }

    list = document.getElementsByClassName('has-submenu')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('d-inline-block')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('text-white')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('bg-overlay')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('shape')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
    list = document.getElementsByClassName('breadcrumb-item')
    for (let item of list) {
      ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
    }
  }
  speakOn=(event)=>{
    this.setState({

    })
    if(window.isSpeakingEnabled)
    {
      window.speechSynthesis.cancel()
    }
    window.isSpeakingEnabled=!window.isSpeakingEnabled
  }
  speak=(event)=>{
    if(window.isSpeakingEnabled)
    {
      this._speech.text =event.currentTarget.textContent;
      this._speech.lang = 'ru-RU'
      window.speechSynthesis.speak(this._speech);}
  }
  render() {
    return (
      <React.Fragment>
        <header id="topnav" className="defaultscroll sticky ">
          <div>
            <div>
            <Link className="logo" style={{fontSize: "13px", marginRight: "2px"}} to="/">
                <span onClick={this.speak} style={{color: "orange", marginLeft: "20px"}}>{this.props.t("test")}</span>
              </Link>
            </div>
            {(() => {
              if (this.props.location.pathname === "/index-developer") {
                return <NavbarButtons />;
              } else if (this.props.location.pathname === "/index-shop") {
                return (
                  <ul className="buy-button list-inline mb-0">
                    <li onClick={this.speak} className="list-inline-item mb-0">
                      <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleDropdown}
                      >
                        <DropdownToggle
                          direction="right"
                          color="none"
                          type="button"
                          className="btn btn-link text-decoration-none p-0 pr-2"
                        >
                          <i className="mdi mdi-magnify h4 text-muted"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          right
                          className="dd-menu bg-white shadow rounded border-0 mt-3 py-0"
                          style={{ width: "300px" }}
                        >
                          <Form>
                            <input
                              type="text"
                              id="text"
                              name="name"
                              className="form-control border bg-white"
                              placeholder="Search..."
                            />
                          </Form>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                    <li onClick={this.speak}className="list-inline-item mb-0 pr-1">
                      <Dropdown
                        isOpen={this.state.dropdownOpenShop}
                        toggle={this.toggleDropdownShop}
                      >
                        <DropdownToggle
                          type="button"
                          className="btn btn-icon btn-primary "
                        >
                          <i className="uil uil-shopping-cart align-middle icons"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          direction="left"
                          className="dd-menu bg-white shadow rounded border-0 mt-3 p-4"
                          style={{ width: "300px" }}
                        >
                          <div className="pb-4">
                            <Link to="#" className="media align-items-center">
                              <img
                                src={shop1}
                                className="shadow rounded"
                                style={{ maxWidth: "64px" }}
                                alt=""
                              />
                              <div className="media-body text-left ml-3">
                                <h6 className="text-dark mb-0">T-shirt (M)</h6>
                                <p className="text-muted mb-0">$320 X 2</p>
                              </div>
                              <h6 className="text-dark mb-0">$640</h6>
                            </Link>
                            <Link
                              to="#"
                              className="media align-items-center mt-4"
                            >
                              <img
                                src={shop2}
                                className="shadow rounded"
                                alt=""
                                style={{ maxWidth: "64px" }}
                              />
                              <div className="media-body text-left ml-3">
                                <h6 className="text-dark mb-0">Bag</h6>
                                <p className="text-muted mb-0">$50 X 5</p>
                              </div>
                              <h6 className="text-dark mb-0">$250</h6>
                            </Link>

                            <Link
                              to="#"
                              className="media align-items-center mt-4"
                            >
                              <img
                                src={shop3}
                                className="shadow rounded"
                                style={{ maxWidth: "64px" }}
                                alt=""
                              />
                              <div className="media-body text-left ml-3">
                                <h6 className="text-dark mb-0">Watch (Men)</h6>
                                <p className="text-muted mb-0">$800 X 1</p>
                              </div>
                              <h6 className="text-dark mb-0">$800</h6>
                            </Link>
                          </div>

                          <div className="media align-items-center justify-content-between pt-4 border-top">
                            <h6 className="text-dark mb-0">Total($):</h6>
                            <h6 className="text-dark mb-0">$1690</h6>
                          </div>

                          <div className="mt-3 text-center">
                            <Link to="#" className="btn btn-primary mr-2">
                              View Cart
                            </Link>
                            <Link to="#" className="btn btn-primary">
                              Checkout
                            </Link>
                          </div>
                          <p className="text-muted text-left mt-1 mb-0">
                            *T&C Apply
                          </p>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                    <li onClick={this.speak}className="list-inline-item mb-0 pr-1">
                      <Link
                        to="#"
                        className="btn btn-icon btn-soft-primary"
                        onClick={this.toggleWishlistModal}
                      >
                        <i className="uil uil-heart align-middle icons"></i>
                      </Link>
                    </li>
                    <li onClick={this.speak}className="list-inline-item mb-0">
                      <Dropdown
                        color="primary"
                        isOpen={this.state.dropdownIsOpen}
                        toggle={this.toggleDropdownIsOpen}
                      >
                        <DropdownToggle
                          type="button"
                          // color="primary"
                          className="btn btn-icon btn-soft-primary"
                        >
                          <i className="uil uil-user align-middle icons"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          direction="left"
                          className="dd-menu bg-white shadow rounded border-0 mt-3 py-3"
                          style={{ width: "200px" }}
                        >
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-user align-middle mr-1"></i>{" "}
                            Account
                          </Link>
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-clipboard-notes align-middle mr-1"></i>{" "}
                            Order History
                          </Link>
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-arrow-circle-down align-middle mr-1"></i>{" "}
                            Download
                          </Link>
                          <div className="dropdown-divider my-3 border-top"></div>
                          <Link className="dropdown-item text-dark" to="#">
                            <i className="uil uil-sign-out-alt align-middle mr-1"></i>{" "}
                            Logout
                          </Link>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                  </ul>
                );
              } else if (
                this.props.location.pathname === "/authority" ||
                this.props.location.pathname === "/shop-lists"
              ) {
                return (
                  <ul className="buy-button list-inline mb-0">
                  </ul>
                );
              } else if (this.props.location.pathname === "/index-seo-agency") {
                return (
                  <div className="buy-button">
                  </div>
                );
              } else {
                return (
                      <div className="buy-button">
                        {!this.state.loggedIn&&<Link
                            onClick={this.speak}
                            to="/signin"
                            rel="noopener noreferrer"
                            id="buyButton"
                            className="btn btn-light  small"
                            style={{fontSize: "12px"}}
                        >
                          <Person fontSize="small"/>
                          {this.props.t('signIn')}
                        </Link>}
                        {this.state.loggedIn&&<Link
                            onClick={this.speak}
                            to="settings"
                            rel="noopener noreferrer"
                            id="buyButton"
                            className="btn btn-light  small"
                            style={{fontSize: "12px"}}
                        >
                         <Settings fontSize="medium"/>
                        </Link>}
                        {this.state.loggedIn&&
                        <button
                            onClick={this.signout}
                            rel="noopener noreferrer"
                            id="buyButton"
                            className="btn btn-light  small"
                        >
                          <img   src={LogOut}/>
                        </button>}
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <button className="btn btn-secondary"  onClick={(event)=>this.changetogray(event)} ><Vision fontSize="small"/></button>
                        {!window.isSpeakingEnabled&&<button className="btn btn-secondary" onClick={this.speakOn}><Microphone
                            fontSize="small"/></button>}
                        {window.isSpeakingEnabled&&<button className="btn btn-secondary" style={{color:"red"}} onClick={this.speakOn}><Microphone
                            fontSize="small" style={{color:"red"}}/></button>}
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button className="btn btn-secondary" style={{fontSize:"12px"}} onClick={this.changeSiteLanguageToUz} ><Flags.UZ/>UZ</button>
                    <span>{"  "}</span>
                    <button className="btn btn-secondary "style={{fontSize:"12px"}}onClick={this.changeSiteLanguageToRu}><Flags.RU/>RU</button>
                    <span>{"  "}</span>
                    <button className="btn btn-secondary "style={{fontSize:"12px",marginRight:"30px"}}onClick={this.changeSiteLanguageToKirill}><Flags.UZ/>Ўз</button>
                  </div>
                );
              }
            })()}

            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>

            <div
              id="navigation"
              style={{ display: this.state.isOpen ? "block" : "none" }}
            >
              <ul className="navigation-menu nav-light" id="top-menu">
                {this.state.navLinks.map((navLink, key) =>
                  navLink.child ? (
                    <li className="has-submenu" key={key}>
                      {/* child item(menu Item) - Level 1 */}
                      <Link
                        to={navLink.link}
                        onClick={(event) => {
                          event.preventDefault();
                          this.openBlock(navLink.id);
                        }}
                      >
                        {navLink.title}
                      </Link>
                      {/* <i className="mdi mdi-chevron-right mr-1"></i> */}
                      <span className="menu-arrow"></span>
                      {navLink.isMegaMenu ? (
                        // if menu is mega menu(2 columns grid)
                        <ul
                          className={
                            navLink.isOpenSubMenu
                              ? "submenu megamenu open"
                              : "submenu megamenu"
                          }
                        >
                          <li>
                            <ul>
                              {navLink.child.map((item, childKey) =>
                                item.id < 18 ? (
                                  <li  onClick={this.speak}key={childKey}>
                                    <Link to={item.link}>
                                      {item.title}
                                      {item.isNew ? (
                                        <span className="badge badge-danger rounded ml-2">
                                          new
                                        </span>
                                      ) : null}
                                    </Link>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                          <li>
                            <ul>
                              {navLink.child.map((item, childKey) =>
                                item.id < 33 && item.id > 17 ? (
                                  <li onClick={this.speak}key={childKey}>
                                    <Link to={item.link}>
                                      {item.title}
                                      {item.isNew ? (
                                        <span className="badge badge-danger rounded ml-2">
                                          new
                                        </span>
                                      ) : null}
                                    </Link>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                          <li>
                            <ul>
                              {navLink.child.map((item, childKey) =>
                                item.id > 32 ? (
                                  <li onClick={this.speak}key={childKey}>
                                    <Link to={item.link}>
                                      {item.title}
                                      {item.isOnePage ? (
                                        <span className="badge badge-warning rounded ml-2">
                                          Onepage
                                        </span>
                                      ) : null}
                                      {item.isupdatePage ? (
                                        <span className="badge badge-pill badge-info">
                                          Updated
                                        </span>
                                      ) : null}
                                    </Link>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                        </ul>
                      ) : (
                        // if menu is not mega menu(1grid)
                        <ul
                          className={
                            navLink.isOpenSubMenu ? "submenu open" : "submenu"
                          }
                        >
                          {navLink.child.map((childArray, childKey) =>
                            childArray.nestedChild ? (
                              // sub menu item - Level 2
                              <li onClick={this.speak}className="has-submenu" key={childKey}>
                                <Link
                                  to={childArray.link}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    this.openNestedBlock(
                                      navLink.id,
                                      childArray.id
                                    );
                                  }}
                                >
                                  {childArray.title}{" "}
                                  {childArray.isNew ? (
                                    <span className="badge badge-pill badge-success">
                                      Added
                                    </span>
                                  ) : null}
                                </Link>
                                <span className="submenu-arrow"></span>
                                <ul
                                  className={
                                    childArray.isOpenNestedSubMenu
                                      ? "submenu open"
                                      : "submenu"
                                  }
                                >
                                  {childArray.nestedChild.map(
                                    (nestedChildArray, nestedKey) => (
                                      // nested sub menu item - Level 3
                                      <li onClick={this.speak}key={nestedKey}>
                                        <Link to={nestedChildArray.link}>
                                          {nestedChildArray.title}{" "}
                                          {nestedChildArray.isNewPage ? (
                                            <span className="badge badge-danger rounded">
                                              NEW
                                            </span>
                                          ) : null}
                                          {nestedChildArray.isupdatePage ? (
                                            <span className="badge badge-pill badge-info">
                                              Updated
                                            </span>
                                          ) : null}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </li>
                            ) : (
                              <li onClick={this.speak}key={childKey}>
                                <Link to={childArray.link}>
                                  {childArray.title}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </li>
                  ) : (
                    <li onClick={this.speak}key={key}>
                      <Link to={navLink.link}>{navLink.title}</Link>
                    </li>
                  )
                )}
              </ul>
              {/*<div className="buy-menu-btn d-none">*/}
              {/*  <Link*/}
              {/*    to="https://1.envato.market/landrickreactjs"*/}
              {/*    target="_blank"*/}
              {/*    rel="noopener noreferrer"*/}
              {/*    className="btn btn-primary"*/}
              {/*  >*/}
              {/*    Buy Now*/}
              {/*  </Link>*/}
              {/*</div>*/}
            </div>
          </div>
        </header>

        <Modal
          isOpen={this.state.wishlistModal}
          tabIndex="-1"
          centered
          contentClassName="rounded shadow-lg border-0 overflow-hidden"
          toggle={this.toggleWishlistModal}
        >
          <ModalBody className="py-5">
            <div className="text-center">
              <div
                className="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
                style={{ height: "95px", width: "95px" }}
              >
                <h1 className="mb-0">
                  <i className="uil uil-heart-break align-middle"></i>
                </h1>
              </div>
              <div className="mt-4">
                <h4>Your wishlist is empty.</h4>
                <p className="text-muted">
                  Create your first wishlist request...
                </p>
                <div className="mt-4">
                  <Link to="#" className="btn btn-outline-primary">
                    + Create new wishlist
                  </Link>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default  withTranslation()(withRouter(Topbar2));
