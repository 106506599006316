import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Form, FormGroup, Row } from "reactstrap";
import {withTranslation} from "react-i18next";

import history from "../../history";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
class landrickTeam extends Component {
  constructor() {
    super();
    this.state={
      searchKey:''
    }
  }
  componentDidMount() {
    this._speech = new SpeechSynthesisUtterance();
  }
  speak=(event)=>{
    if(window.isSpeakingEnabled)
    {
      this._speech.text =event.currentTarget.textContent;
      this._speech.lang = 'ru-RU'
      window.speechSynthesis.speak(this._speech);}
  }
  speak2=(x)=>{
    if(window.isSpeakingEnabled)
    {
      this._speech.text =x
      this._speech.lang = 'ru-RU'
      window.speechSynthesis.speak(this._speech);}
  }
  checkNotEmptyKey=()=>{
    toast.configure()
    if(this.state.searchKey==='')
      toast.warning(this.props.t("kalitSK"))
    else if(this.state.searchKey!=='')
      history.push({
        pathname: '/search-results',
        state: this.state.searchKey
      })
  }
  checkNotEmptyKey2=(event)=>{

    if (event.charCode === 13) {
      toast.configure()
      if(this.state.searchKey==='')
        toast.warning(this.props.t("kalitSK"))
      else if(this.state.searchKey!=='')
        history.push({
          pathname: '/search-results',
          state: this.state.searchKey
        })}
  }
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title">
                <h4 onClick={this.speak} className="title mb-4">
                  {this.props.t("saytBQ")}
                </h4>


                <div className="subcribe-form mt-4 pt-2">
                  <Form>
                    <FormGroup>
                      <input
                          onKeyPress={(event)=>this.checkNotEmptyKey2(event)}
                          onClick={()=>this.speak2(this.props.t('kalitSK'))}
                        id="help"
                        className="border bg-white rounded-pill"
                        required
                        placeholder={this.props.t('kalitSK')}
                        onChange={(event)=>this.setState({searchKey:event.target.value})}
                      />
                      <div onClick={this.speak}>
                      <Button onClick={this.checkNotEmptyKey} className="btn btn-pills btn-primary">
                        {this.props.t("search")}
                      </Button>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default withTranslation()(landrickTeam)