import axios from "axios";
import {API_URL} from "../Constants";
import AuthenticationService from "./AuthenticationService";
import {createBrowserHistory} from "history";

const historyForce=createBrowserHistory({
    forceRefresh: true
});

const instance = axios.create({
    baseURL: `${API_URL}/api`,
    withCredentials:true
});

instance.interceptors.response.use(
    (res) => {
        return res;
    },

    async (err) => {
        const originalConfig = err.config;
        if ((originalConfig.url === `${API_URL}/api/auth/check/` && err.response)) {

        }
        else
        if ((originalConfig.url === `${API_URL}/api/auth/check2/` && err.response)) {
            historyForce.push("/signin")
        }
        else
        if ((originalConfig.url === `${API_URL}/api/auth/refreshtoken/` && err.response)) {
            historyForce.push("/signin")
        }
        else

       if ((originalConfig.url !== `${API_URL}/api/auth/signin/` && err.response)) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    await AuthenticationService.refreshAccessToken()
                    return instance(originalConfig);
                } catch (_error) {
                    historyForce.push("/signin")
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);
export default instance;